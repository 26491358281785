import axios from 'axios'

const BASE_URL = `${process.env.REACT_APP_DATA_LOGGER_BASE_URL}/v1`

export const getDailyReportMachineWiseV2 = async (params) => {
  console.log(params)
  try {
    const response = await axios.get(
      `${BASE_URL}/production-info/machine-wise`,
      {
        params: params
      }
    )
    return response
  } catch (err) {
    console.log(err)
    return err.response
  }
}

export const getDailyReportPartWiseV2 = async (params) => {
  console.log(params)
  try {
    const response = await axios.get(`${BASE_URL}/production-info/part-wise`, {
      params: params
    })
    return response
  } catch (err) {
    console.log(err)
    return err.response
  }
}

export const getIdletimeDataForDay = async (params) => {
  console.log(params)
  try {
    const response = await axios.get(
      `${BASE_URL}/production-info/idletime-for-day`,
      {
        params: params
      }
    )
    return response
  } catch (err) {
    console.log(err)
    return err.response
  }
}

export const getRejectionDataForDay = async (params) => {
  console.log(params)
  try {
    const response = await axios.get(
      `${BASE_URL}/production-info/rejection-for-day`,
      {
        params: params
      }
    )
    return response
  } catch (err) {
    console.log(err)
    return err.response
  }
}

export const getSpillOverData = async (params) => {
  console.log(params)
  try {
    const response = await axios.get(
      `${BASE_URL}/production-info/production-order-data`
    )
    return response
  } catch (err) {
    console.log(err)
    return err.response
  }
}
