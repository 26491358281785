import React from 'react'
import Box from '@mui/material/Box'
import { Doughnut } from 'react-chartjs-2'
import * as CHART_UTILS from './Doughnut.constant'

const DoughnutChart = ({ /*chartTitle,*/ chartLabels, dataGroup }) => {
  const myChartRef = React.createRef()

  const doughnutChart = dataGroup ? (
    <Doughnut
      ref={myChartRef}
      data={{
        labels: chartLabels,
        datasets: [
          {
            ...CHART_UTILS.DOUGHNUT,
            data: dataGroup
          }
        ]
      }}
      height={350}
      width={400}
      options={{
        maintainAspectRatio: false,
        plugins: {
          title: {
            ...CHART_UTILS.TITLE
            //text: `${chartTitle}`,
          },
          tooltip: CHART_UTILS.TOOTIP,
          legend: {
            ...CHART_UTILS.LEGEND
          }
        }
      }}
    />
  ) : null

  return <Box sx={{ width: '100%', height: '400px' }}>{doughnutChart}</Box>
}

export default DoughnutChart
