import React from 'react'
import GlobalStyles from '@mui/material/GlobalStyles'

const globalStyles = (
  <GlobalStyles
    styles={{
      '*': {
        margin: 0,
        padding: 0,
        boxSizing: 'border-box'
        // '&::-webkit-scrollbar': {
        //   width: '8px',
        // },
        // '&::-webkit-scrollbar-track': {
        //   backgroundColor: 'transparent',
        // },
        // '&::-webkit-scrollbar-thumb': {
        //   backgroundColor: 'rgba(0,0,0,0.3)',
        // },
      },
      html: {
        width: '100%',
        height: '100vh',
        fontSize: '16px',
        fontFamily: 'Inter',
        lineHeight: '1.43',
        MozOsxFontSmoothing: 'grayscale',
        WebkitFontSmoothing: 'antialiased'
      },
      body: {
        width: '100%',
        minHeight: '100%',
        overflow: 'auto'
      },
      '#root': {
        height: '100vh',
        width: '100%'
      },
      a: {
        textDecoration: 'none'
      }
    }}
  />
)

export default globalStyles
