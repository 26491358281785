import axios from 'axios'

const axiosObj = axios.create({
  baseURL: `${process.env.REACT_APP_ADMIN_BASE_URL}/v1`,
  headers: {
    'Content-Type': 'application/json'
  },
  responseType: 'json'
})

const shiftConfigService = {
  getShiftConfigById: async (id) => {
    try {
      const response = await axiosObj.get(`/shift-config/${id}`)

      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  getShiftConfigs: async () => {
    try {
      const response = await axiosObj.get('/shift-config')

      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  addShiftConfig: async (data) => {
    try {
      const response = await axiosObj.post('/shift-config', data)
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  updateShiftConfig: async (id, data) => {
    try {
      const response = await axiosObj.patch(`/shift-config/${id}`, data)
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  deleteShiftConfig: async (id, data) => {
    try {
      const response = await axiosObj.delete(`/shift-config/${id}`, data)
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  selectShiftConfig: async (id, arrayOfIds) => {
    try {
      const response = await axiosObj.patch(`/shift-config/select/${id}`, {
        arrayOfIds
      })
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  }
}

export default shiftConfigService
