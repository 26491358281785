import axios from 'axios'

import {
  IProductionOrder,
  ProductionOrderFormFields,
  ProductionOrderFilter,
  IProductionOrderApiNew,
  ProductionOrderFormFieldsNew
} from '../types/productionOrder'
import { ApiErrorResponse, ApiSuccessResponse } from '../types/common'
import { handleApiError } from './part.service'

const axiosObj = axios.create({
  baseURL: `${process.env.REACT_APP_ADMIN_BASE_URL}/v1`,
  headers: {
    'Content-Type': 'application/json'
  },
  responseType: 'json'
})

const productionOrderService = {
  getProductionOrders: async (
    data: ProductionOrderFilter
  ): Promise<ApiSuccessResponse<IProductionOrder[]> | ApiErrorResponse> => {
    try {
      const response = await axiosObj.get('/production-order')

      return response.data
    } catch (error) {
      return handleApiError(error)
    }
  },
  addProductionOrder: async (
    data: ProductionOrderFormFields
  ): Promise<ApiSuccessResponse<IProductionOrder> | ApiErrorResponse> => {
    try {
      const response = await axiosObj.post('/production-order', data)
      return response.data
    } catch (error) {
      return handleApiError(error)
    }
  },
  editProductionOrder: async (
    workOrderNum: number,
    data: ProductionOrderFormFields
  ): Promise<ApiSuccessResponse<IProductionOrder> | ApiErrorResponse> => {
    try {
      const response = await axiosObj.patch(
        `/production-order/${workOrderNum}`,
        data
      )
      return response.data
    } catch (error) {
      return handleApiError(error)
    }
  },
  deleteProductionOrder: async (
    workOrderNum: number
  ): Promise<ApiSuccessResponse<{}> | ApiErrorResponse> => {
    try {
      const response = await axiosObj.delete(
        `/production-order/${workOrderNum}`
      )
      return response.data
    } catch (error) {
      return handleApiError(error)
    }
  },

  getProductionOrdersNew: async (
    data?: any
  ): Promise<
    ApiSuccessResponse<IProductionOrderApiNew[]> | ApiErrorResponse
  > => {
    try {
      const response = await axiosObj.get('/production-order-new')

      return response.data
    } catch (error) {
      return handleApiError(error)
    }
  },
  addProductionOrderNew: async (
    data: ProductionOrderFormFieldsNew
  ): Promise<ApiSuccessResponse<IProductionOrderApiNew> | ApiErrorResponse> => {
    try {
      const response = await axiosObj.post('/production-order-new', data)
      return response.data
    } catch (error) {
      return handleApiError(error)
    }
  },
  editProductionOrderNew: async (
    workOrderNum: number,
    data: ProductionOrderFormFieldsNew
  ): Promise<ApiSuccessResponse<IProductionOrderApiNew> | ApiErrorResponse> => {
    try {
      const response = await axiosObj.patch(
        `/production-order-new/${workOrderNum}`,
        data
      )
      return response.data
    } catch (error) {
      return handleApiError(error)
    }
  },
  addToolToProductionOrderToolMap: async (data: {
    workOrderNumber: number
    toolNumber: number
    toolTypeId: number
    toolType: string
    toolId: string
    reason: string
  }): Promise<ApiSuccessResponse<{ isUsed: boolean }> | ApiErrorResponse> => {
    try {
      const response = await axiosObj.post(
        `/production-order-new/${data.workOrderNumber}/add-tool`,
        data
      )

      return response.data
    } catch (error) {
      return handleApiError(error)
    }
  },
  checkPOIsUsedOrNot: async (
    poNumber: number
  ): Promise<ApiSuccessResponse<{ isUsed: boolean }> | ApiErrorResponse> => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DATA_LOGGER_BASE_URL}/v1/production-info/check-is-po-used/${poNumber}`
      )

      return response.data
    } catch (error) {
      return handleApiError(error)
    }
  }
}

export default productionOrderService
