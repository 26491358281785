import React, { useState, useEffect } from 'react'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import StyledModal from '../../components/StyledModal/Modal'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'
import StyledTextFieldWithLabel from '../../components/StyledTextFieldWithLabel/StyledTextFieldWithLabel'
import { MACHINES } from '../../utils/constant.utils'
import { ToolsApiDataWithIndex, ITool } from '../../types/tools'
import { OptionItem } from '../../types/common'

interface Props {
  isModalOpen: boolean
  closeHandler: () => void
  selectedTool: ToolsApiDataWithIndex | null
  addTool?: (toolData: ITool) => void
  editTool?: (id: string, newData: ITool) => void
  isEditMode?: boolean
  isLoading?: boolean
}

const ToolUploadModal = (props: Props) => {
  let defaultToolId = ''
  let defaultToolType: OptionItem = {
    value: 0,
    label: 'Select tool type'
  }
  let defaultToolSize = ''
  let defaultToolLife: string | number = ''
  let defaultMachine: OptionItem = {
    value: 0,
    label: 'Select machine'
  }

  if (props.isEditMode && props.selectedTool) {
    defaultToolId = props.selectedTool.toolId

    defaultToolType.value = props.selectedTool.toolTypeId
    defaultToolType.label = props.selectedTool.toolTypeId

    defaultMachine.value = props.selectedTool.machineId
    defaultMachine.label = props.selectedTool.machineId.toUpperCase()

    defaultToolSize = props.selectedTool.toolSize
    defaultToolLife = props.selectedTool.toolLife
  }

  const [toolTypeOptions, setToolTypeOptions] = useState([])
  const [toolIdNo, setToolIdNo] = useState(defaultToolId)
  const [toolType, setToolType] = useState(defaultToolType)
  const [toolSize, setToolSize] = useState(defaultToolSize)
  const [machine, setMachine] = useState(defaultMachine)
  const [toolLife, setToolLife] = useState(defaultToolLife)

  useEffect(() => {
    fetch(`${process.env.REACT_APP_ADMIN_BASE_URL}/v1/tool-type`)
      .then((response) => response.json())
      .then((data) => {
        const toolTypeOptions = data.data.map((item: any) => ({
          value: item.id,
          label: item.type
        }))
        setToolTypeOptions(toolTypeOptions)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  return (
    <StyledModal
      width={500}
      loading={props.isLoading}
      isOpen={props.isModalOpen}
      title={props.isEditMode ? 'Edit tool info' : 'Add tool'}
      closeHandler={() => props.closeHandler()}
    >
      <Stack
        spacing={2}
        direction="column"
        sx={{ marginBottom: '16px', width: '100%' }}
      >
        <StyledSelectField
          label="Tool type"
          placeholderText="Enter tool type"
          selectedValue={toolType}
          selectOptions={toolTypeOptions}
          onChangeHandler={(value: OptionItem) => setToolType(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No tool type found"
        />
        <StyledTextFieldWithLabel
          label="Tool size"
          placeholderText="Enter tool size"
          textValue={toolSize}
          onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
            setToolSize(e.target.value)
          }}
          isDisabled={false}
          type="text"
          isMargin={true}
          noOptionsMessage="No tool size entered"
        />
        <StyledTextFieldWithLabel
          label="Tool id"
          placeholderText="Enter tool id"
          textValue={toolIdNo}
          onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
            setToolIdNo(e.target.value)
          }}
          isDisabled={false}
          type="text"
          isMargin={true}
          noOptionsMessage="No tool id no entered"
        />
        <StyledTextFieldWithLabel
          label="Tool life"
          placeholderText="Enter tool life"
          textValue={toolLife}
          onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = e.target.value
            if (parseInt(inputValue) < 0) {
              // If it's below zero, set the value to an empty string
              setToolLife('')
            } else {
              // Otherwise, update the state with the new value
              setToolLife(parseInt(inputValue))
            }
          }}
          isDisabled={false}
          type="number"
          isMargin={true}
        />
        <StyledSelectField
          label="Machine"
          placeHolderText="Select machine"
          selectedValue={machine}
          selectOptions={MACHINES}
          onChangeHandler={(value: OptionItem) => setMachine(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No machine found"
        />
      </Stack>
      <Stack
        spacing={2}
        direction="row"
        sx={{
          marginTop: '16px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button variant="outlined" onClick={props.closeHandler}>
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ color: '#fff' }}
          onClick={() =>
            props.isEditMode && props.selectedTool
              ? props.editTool &&
                props.editTool(props.selectedTool.toolId, {
                  toolTypeId: toolType.value,
                  toolId: toolIdNo,
                  toolSize: toolSize,
                  toolLife: parseInt(String(toolLife)),
                  machineId: machine.value
                })
              : props.addTool &&
                props.addTool({
                  toolId: toolIdNo,
                  toolTypeId: toolType.value,
                  toolSize: toolSize,
                  toolLife: parseInt(String(toolLife)),
                  machineId: machine.value
                })
          }
        >
          {props.isEditMode ? 'Update' : 'Add tool'}
        </Button>
      </Stack>
    </StyledModal>
  )
}

export default ToolUploadModal
