import React, { useState, useEffect } from 'react'

//service
import shiftConfigService from '../../../service/shiftConfig.service'

//utils
import { showNotification } from '../../../utils/toast.utils'
import ShiftConfigCard from './ShiftConfigCard'

const ConfigList = () => {
  const [configList, setConfigList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const fetchConfig = async () => {
    try {
      setIsLoading(true)
      const response = await shiftConfigService.getShiftConfigs()
      console.log(response.data.data)
      if (response.data.success) {
        setConfigList(response.data.data)
      } else {
        showNotification(response.data.message, 'error')
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchConfig()
  }, [])

  const selectShiftConfig = async (id) => {
    let arrayOfIds = []
    configList.forEach((data) => {
      if (data.id !== id) {
        arrayOfIds.push(data.id)
      }
    })

    try {
      setIsLoading(true)
      const response = await shiftConfigService.selectShiftConfig(
        id,
        arrayOfIds
      )
      if (response.data.success) {
        showNotification('Shift selection successful', 'success')
        fetchConfig()
      }
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  const deleteShiftConfig = async (id) => {
    try {
      setIsLoading(true)
      const response = await shiftConfigService.deleteShiftConfig(id)
      if (response.data.success) {
        showNotification('Shift config deleted successful', 'success')
        fetchConfig()
      }
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return <>Loading...</>
  }

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '24px'
      }}
    >
      {React.Children.toArray(
        configList.map((data) => (
          <ShiftConfigCard
            data={data}
            selectShiftConfig={selectShiftConfig}
            deleteShiftConfig={deleteShiftConfig}
          />
        ))
      )}
    </div>
  )
}

export default ConfigList
