import React from 'react'
import { ExpandMore } from '@mui/icons-material'
import { Stack, Box, Typography, IconButton } from '@mui/material'

import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'
import StyledTextFieldWithLabel from '../../components/StyledTextFieldWithLabel/StyledTextFieldWithLabel'
import { ToolsApiData } from '../../types/tools'
import { OptionItem } from '../../types/common'

const TOOL_CHANGE_REASONS = [
  { label: 'Select reason', value: 'not_selected' },
  //{ label: 'Initial entry', value: 'initial_entry' },
  { label: 'End of Tool Life', value: 'end_of_tool_life' },
  { label: 'To fix the wrong entry', value: 'to_fix_the_wrong_entry' },
  { label: 'Tool break down', value: 'tool_break_down' },
  { label: 'Tool thickness variation', value: 'tool_thickness_variation' }
]

const FormFieldItemToolId = ({
  toolId,
  toolTypeId,
  toolNumber,
  toolType,
  toolIdOptions,
  toolIdChangeHandler,
  reasonChangeHandler,
  isToolChangeFlow,
  isPOUsed,
  reason
}: {
  toolId: string | null
  toolTypeId: number
  toolIdOptions: ToolsApiData[]
  toolNumber: number
  toolType: string
  reason: string | null
  toolIdChangeHandler: (value: string) => void
  reasonChangeHandler: (value: string) => void
  isToolChangeFlow?: boolean
  isPOUsed?: boolean
}) => {
  const [showDetails, setShowDetails] = React.useState(true)

  const toggleShowDetails = () => {
    setShowDetails((prev) => !prev)
  }

  const requiredTool = toolIdOptions.find((item) => item.toolId === toolId)

  let selectedReason = TOOL_CHANGE_REASONS.find((item) => item.value === reason)
  if (!selectedReason) {
    selectedReason = TOOL_CHANGE_REASONS[0]
  }

  const canUpdateTool = isPOUsed ? toolId === null : true

  return (
    <Stack
      sx={{
        ...(!canUpdateTool && {
          pointerEvents: 'none'
        }),
        border: `1px solid gray`,
        borderRadius: '4px',
        padding: showDetails ? '8px 16px' : '0px'
      }}
    >
      <Box
        onClick={toggleShowDetails}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: showDetails ? '0px' : '6px 12px',
          cursor: 'pointer'
        }}
      >
        <Typography sx={{ color: '#000' }}>Tool {toolNumber}</Typography>
        <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <IconButton
            sx={{ display: !isToolChangeFlow ? 'block' : 'none' }}
            size="small"
            onClick={(e) => {
              e.stopPropagation()
              toggleShowDetails()
            }}
          >
            <ExpandMore
              fontSize="small"
              sx={{
                transform: showDetails ? 'rotate(180deg)' : 'none'
              }}
            />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          height: showDetails ? 'auto' : 0,
          ...(!showDetails && {
            overflow: 'hidden'
          })
        }}
      >
        <Stack sx={{ flex: 1, gap: '8px' }}>
          <StyledTextFieldWithLabel
            placeholderText="Enter tool number"
            textValue={`${toolNumber}-${toolType}`}
            isDisabled={true}
            type="text"
          />
          <StyledSelectField
            label="Tool ID"
            placeHolderText="Select tool id"
            selectedValue={
              requiredTool
                ? {
                    label: requiredTool.toolId,
                    value: requiredTool.toolId
                  }
                : {
                    label: 'Select tool id',
                    value: 0
                  }
            }
            selectOptions={toolIdOptions
              .filter((item) => item.toolType.id === toolTypeId)
              .map((item) => ({
                label: item.toolId,
                value: item.toolId
              }))}
            onChangeHandler={(selectedOption: OptionItem) =>
              toolIdChangeHandler(selectedOption.value)
            }
            isMulti={false}
            isSearchable={true}
            isDisabled={!canUpdateTool}
            noOptionsMessage="No tool id found"
          />
          {isToolChangeFlow && (
            <StyledSelectField
              label="Reason"
              placeHolderText="Select the reason"
              selectedValue={selectedReason}
              selectOptions={TOOL_CHANGE_REASONS}
              onChangeHandler={(selectedOption: OptionItem) => {
                if (selectedOption && reasonChangeHandler) {
                  reasonChangeHandler(selectedOption.value)
                }
              }}
              isMulti={false}
              isDisabled={false}
            />
          )}
        </Stack>
      </Box>
    </Stack>
  )
}

export default FormFieldItemToolId
