import React from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import StyledSelectField from '../../../../components/StyledSelectField/StyledSelectField'

import * as DATE_UTILS from '../../../../utils/date.utils'

const Container = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}))

const ActionBtnContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px'
}))

const DateForm = styled('form')(() => ({
  display: 'flex',
  gap: '8px'
}))

const DateInput = styled('input')(() => ({
  outline: 'none',
  border: '1px solid #969696',
  padding: '4px 8px',
  borderRadius: '8px',
  fontSize: '14px',
  fontWeight: 600,
  '&::-webkit-calendar-picker-indicator': {
    padding: '4px',
    marginLeft: '4px',
    borderRadius: '4px',
    cursor: 'pointer'
  }
}))

const InputHeader = ({
  date,
  dateChangeHandler,
  submitHandler,
  prevNextHandler,
  machineData,
  isLoading
}) => {
  return (
    <Container>
      <DateForm>
        <DateInput
          id="queryDate"
          type="date"
          //type="month"
          value={date}
          min="2021-01-15"
          max={DATE_UTILS.maxDate()}
          onChange={dateChangeHandler}
        />
        <Button disabled={isLoading} variant="outlined" onClick={submitHandler}>
          Submit
        </Button>
      </DateForm>
      <ActionBtnContainer>
        <Button
          disabled={isLoading}
          variant="outlined"
          onClick={() => prevNextHandler('prev')}
        >
          <ArrowBackIcon />
        </Button>
        <Button
          disabled={isLoading}
          variant="outlined"
          onClick={() => prevNextHandler('next')}
        >
          <ArrowForwardIcon />
        </Button>
      </ActionBtnContainer>
      <div style={{ marginLeft: 'auto', display: 'flex', width: '300px' }}>
        {machineData && (
          <StyledSelectField
            label=""
            placeHolderText="Select machine"
            selectedValue={machineData.selectedMachine}
            selectOptions={machineData.machineOptions}
            onChangeHandler={(value) => machineData.setSelectedMachine(value)}
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No machine found"
          />
        )}
      </div>
    </Container>
  )
}

export default InputHeader
