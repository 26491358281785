import React, { useState } from 'react'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

import DoughnutChart from '../Charts/DonutChart/DoughnutChart'

const idletimeDataMapper = (dataObj, idleReasonMap) => {
  const chartData = []
  for (const property in dataObj) {
    chartData.push({
      reasonIdentifier: property,
      label: idleReasonMap[property],
      value: dataObj[property]
    })
  }

  chartData.sort((a, b) => b.value - a.value)

  return {
    labels: chartData.map((el) => el['label']),
    values: chartData.map((el) => el['value'])
  }
}

const Container = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid lightgray',
  borderRadius: '12px'
}))

const CardHeader = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px',
  borderBottom: '1px solid lightgray'
}))

const CardBody = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '12px'
}))

const CardFooter = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  borderTop: '1px solid lightgray',
  padding: '12px'
}))

const ChartWithoutControls = ({ idleTimeData, idleReasonMap }) => {
  console.log('idleTimeData', idleTimeData)
  const chartData = idletimeDataMapper(idleTimeData, idleReasonMap)
  const totalCount = chartData
    ? chartData['values'].reduce((accu, element) => accu + element, 0)
    : 0

  return (
    <Container>
      <CardHeader>
        <h3>Idletime</h3>
        <h4>
          Total Idletime = {totalCount && totalCount && totalCount.toFixed(0)}{' '}
          Minutes
        </h4>
      </CardHeader>
      <CardBody>
        <DoughnutChart
          chartTitle=""
          chartLabels={chartData.labels}
          dataGroup={chartData.values}
        />
      </CardBody>
    </Container>
  )
}

export default ChartWithoutControls
