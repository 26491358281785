import React, { useState, useRef } from 'react'
import { styled, Button } from '@mui/material'

//components
import LiveDataBar from '../components/LiveDataBar'
import LiveDataDetailedBar from '../components/LiveDataDetailedBar'

const DataBarContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '16px',
  width: '100%'
}))

const Overview = ({ isLoading, liveData, groupedData }) => {
  //hooks
  const mainRef = useRef(null)

  const [isShown, setIsShown] = useState(false)

  return (
    <div>
      <DataBarContainer ref={mainRef}>
        {isLoading && <div>Loading...</div>}
        {!isLoading && liveData && (
          <LiveDataBar
            liveData={Object.values(liveData)}
            width={1120}
            //width={width} //TODO: fix this issue
            count={Object.values(liveData).length}
            isBorder={true}
          />
        )}
        <Button
          color="primary"
          style={{
            backgroundColor: 'black',
            color: 'white',
            minWidth: '300px',
            border: '1px solid transparent',
            marginLeft: 'auto',
            '&:hover': {
              border: '1px solid black',
              backgroundColor: 'transparent',
              color: 'black'
            }
          }}
          onClick={() => setIsShown((prev) => !prev)}
        >
          {isShown ? 'Show less' : 'Show detailed analysis'}
        </Button>
        {isShown && <LiveDataDetailedBar liveData={groupedData} width={600} />}
      </DataBarContainer>
    </div>
  )
}

export default Overview
