import React, { useState, useEffect } from 'react'
import Stack from '@mui/material/Stack'
import { Typography, Box } from '@mui/material'

//components
import StyledTable from '../../components/ReactTable/StyledTable'
import StyledTableContainer from '../../components/StyledTableContainer/StyledTableContainer'
import StyledEmptyHeader from '../../components/StyledEmptyHeader/StyledEmptyHeader'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'

//services
import { MACHINES } from '../../utils/constant.utils'
import liveDataService from '../../service/liveData.service'
import { getDisplayName } from '../../utils/common.utils'
import { useDateSelectorContext } from '../../context/DateSelectorContext'
import { Parser } from '@json2csv/plainjs'

const machineOptions = [...MACHINES]
const headers = [
  { label: 'Date', value: 'date' },
  { label: 'Shift', value: 'shift' },
  { label: 'Machine', value: 'machine' },
  { label: 'Operator', value: 'operator' },
  { label: 'Product family', value: 'partName' },
  { label: 'Part number', value: 'partType' },
  { label: 'Idle reason', value: 'idleReason' },
  { label: 'Shift target', value: 'shiftTarget' },
  { label: 'Production quantity', value: 'partCount' },
  { label: 'IdleTime', value: 'idleTime' }
]

const ProductionLossIdleTime = () => {
  //context
  const { selectedDate } = useDateSelectorContext()

  //local
  const [productionLossRows, setProductionLossRows] = useState([])
  const [machine, setMachine] = useState(machineOptions[0])
  const [availableShifts, setAvailableShifts] = useState([])
  const [selectedShift, setSelectedShift] = useState({
    label: 'All shifts',
    value: 'all'
  })
  const [availableIdleReasons, setAvailableIdleReasons] = useState([])
  const [selectedIdleReason, setSelectedIdleReason] = useState({
    label: 'All idle reasons',
    value: 'all'
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (machine === null) return
    const fetchProductionLossRecords = async () => {
      const params = {}

      if (selectedDate) {
        params.date = selectedDate
      }

      if (machine.value !== 0) {
        params.machine = machine.value
      }

      try {
        setIsLoading(true)
        const ProductionLossResponse =
          await liveDataService.getProductionLossTime(params)
        console.log(ProductionLossResponse)
        const data = ProductionLossResponse.data.data
        const availableShifts = []
        const availableIdleReasonsFromAPI = []
        const partsData = data.map((data, i) => {
          const shiftValue = `S${data.shift}`

          if (!availableShifts.includes(shiftValue)) {
            availableShifts.push(shiftValue)
          }
          if (!availableIdleReasonsFromAPI.includes(data.idleReason)) {
            availableIdleReasonsFromAPI.push(data.idleReason)
          }

          return {
            date: data.date,
            machine: data.machine,
            shift: shiftValue,
            operator: data.operator,
            partName: data.partName,
            partType: data.partType,
            idleReason: data.idleReason,
            shiftTarget: data.shiftTarget,
            totalPartCount: data.totalPartCount,
            idleTime: data.idleTime
          }
        })

        setAvailableShifts([
          {
            label: 'All shifts',
            value: 'all'
          },
          ...availableShifts.map((shift) => {
            return { label: shift, value: shift }
          })
        ])
        setAvailableIdleReasons([
          {
            label: 'All idle reason',
            value: 'all'
          },
          ...availableIdleReasonsFromAPI.map((reason) => {
            return { label: reason, value: reason }
          })
        ])

        setProductionLossRows(partsData)
      } catch (err) {
        console.log(err)
        setProductionLossRows([])
      } finally {
        setIsLoading(false)
      }
    }
    fetchProductionLossRecords()
  }, [selectedDate, machine])

  // /1. Create search object
  const searchObj = {
    ...(selectedShift &&
      selectedShift.value &&
      selectedShift.value !== 'all' && {
        shift: selectedShift.value
      }),
    ...(machine &&
      (machine.value !== 'all' || machine.value === 0) && {
        machine: machine.value
      }),
    ...(selectedIdleReason &&
      selectedIdleReason.value &&
      selectedIdleReason.value !== 'all' && {
        idleReason: selectedIdleReason.value
      })
  }

  //2. Extract the keys into an array
  const keysToChecked = Object.keys(searchObj)

  //3. filter
  let filteredRows = productionLossRows
  if (keysToChecked.length > 0) {
    filteredRows = productionLossRows.filter((row) => {
      return keysToChecked.every((key) => searchObj[key] === row[key])
    })
  }

  const totalIdleTime = filteredRows.reduce((acc, cv) => {
    const idleTimeCount = Number(cv.idleTime)

    if (!isNaN(idleTimeCount)) {
      return acc + idleTimeCount
    } else {
      return acc
    }
  }, 0)

  const productionLossColumns = [
    {
      Header: 'Machine',
      accessor: 'machine',
      Cell: ({ cell: { row } }) => {
        return getDisplayName(row.values.machine, MACHINES)
      }
    },
    {
      Header: 'Shift',
      accessor: 'shift'
    },
    {
      Header: 'Operator',
      accessor: 'operator'
    },
    {
      Header: 'Product family',
      accessor: 'partName'
    },
    {
      Header: 'Part number',
      accessor: 'partType'
    },
    {
      Header: 'Idle reason',
      accessor: 'idleReason'
    },
    {
      Header: 'Shift target',
      accessor: 'shiftTarget'
    },
    {
      Header: 'Production quantity',
      accessor: 'totalPartCount'
    },
    {
      Header: 'Idle time',
      accessor: 'idleTime'
    }
  ]

  const csvDownload = async () => {
    try {
      setIsLoading(true)
      const response = await liveDataService.getProductionLossMonthWise({
        date: selectedDate
      })
      // console.log(response)
      const data = response.data.data

      const parser = new Parser({
        fields: [...headers]
      })
      const formattedCSV = parser.parse([...data])
      const blob = new Blob([formattedCSV], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Production-loss-${selectedDate}.csv`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Stack
        sx={{
          position: 'sticky',
          top: 0,
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '24px',
          padding: '16px',
          backgroundColor: '#fff',
          borderBottom: '1px solid #eeeeee'
        }}
      >
        <StyledSelectField
          label=""
          placeHolderText="Select shift"
          selectedValue={selectedShift}
          selectOptions={availableShifts}
          onChangeHandler={(value) => setSelectedShift(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No shift found"
        />
        <StyledSelectField
          label=""
          placeHolderText="Select machine"
          selectedValue={machine}
          selectOptions={machineOptions}
          onChangeHandler={(value) => setMachine(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No machine found"
        />
        <StyledSelectField
          label=""
          placeHolderText="Select idle reason"
          selectedValue={selectedIdleReason}
          selectOptions={availableIdleReasons}
          onChangeHandler={(value) => setSelectedIdleReason(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No idle reason found"
        />
        <button
          onClick={csvDownload}
          style={{
            position: 'relative',
            width: '150px',
            height: '50px',
            display: 'flex',
            padding: '16px',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#fff',
            boxShadow: '0 2px 7px 0 rgb(18 18 19 / 6%)',
            transition:
              'box-shadow 300ms ease, color 300ms ease, border-color 300ms ease',
            '&:hover': {
              borderColor: '#a8a8b5',
              boxShadow: '0 2px 12px 0 rgb(18 18 19 / 10%)'
            },
            color: '#000',
            borderRadius: '8px',
            border: '1px solid #d9d9d9',
            cursor: 'pointer',
            fontWeight: 600,
            fontSize: '16px',
            '&:hover': { backgroundColor: '#fafafa' }
          }}
        >
          Export
          <FileDownloadOutlinedIcon sx={{ marginLeft: '4px' }} />
        </button>
      </Stack>
      <Stack sx={{ gap: '16px', padding: '16px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: '32px'
          }}
        >
          <Typography sx={{ fontWeight: 400 }}>
            Total idle time:{' '}
            <span style={{ fontWeight: 600 }}>{totalIdleTime}</span>
          </Typography>
                  
        </Box>
        {isLoading && <div>Loading...</div>}
        {!isLoading && filteredRows.length === 0 && (
          <StyledEmptyHeader>
            There are no production loss idle reasons records for the selected
            date
          </StyledEmptyHeader>
        )}
        {!isLoading && filteredRows.length > 0 && (
          <StyledTableContainer>
            <StyledTable
              pagination={false}
              columns={productionLossColumns}
              data={filteredRows}
              hiddenColumns={['machine']}
            />
          </StyledTableContainer>
        )}
      </Stack>
    </>
  )
}

export default ProductionLossIdleTime
