import axios from 'axios'

const axiosObj = axios.create({
  baseURL: `${process.env.REACT_APP_ADMIN_BASE_URL}/v1`,
  headers: {
    'Content-Type': 'application/json'
  },
  responseType: 'json'
})

const rejectionReasonMapService = {
  addRejectionReasons: async (data) => {
    try {
      const response = await axiosObj.post('/rejection-reasons', data)
      return response.data
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  getRejectionReasons: async (partType) => {
    try {
      const response = await axiosObj.get('/rejection-reasons', {
        params: { partType }
      })
      return response.data
    } catch (err) {
      console.log(err)
      return err.response
    }
  }
}

export default rejectionReasonMapService
