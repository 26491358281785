import React, { useState, useEffect } from 'react'
import { Box, Stack } from '@mui/material'

import DemographyCard from './components/DemographyCard/DemographyCard'
import liveDataService from '../../service/liveData.service'
import { showNotification } from '../../utils/toast.utils'
import { getDisplayName } from '../../utils/common.utils'
import { MACHINES } from '../../utils/constant.utils'
import { useMonthSelectorContext } from '../../context/MonthSelectorContext'

const MyMachineDashboard = ({ tabChange }) => {
  const { selectedDate } = useMonthSelectorContext()

  const [isLoading, setIsLoading] = useState(false)
  const [machineWiseData, setMachineWiseData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const response = await liveDataService.getDemographyData({
          currentDate: selectedDate
        })

        if (response.data.success) {
          console.log({ data: response.data })
          setMachineWiseData(response.data.data.machineWiseData)
        } else {
          showNotification(response.data.message, 'error')
        }
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [selectedDate])

  return (
    <>
      <Box
        sx={{ display: 'flex', flexWrap: 'wrap', gap: '24px', padding: '16px' }}
      >
        {isLoading && <>Loading...</>}
        {!isLoading && machineWiseData.length === 0 && (
          <>No data available, try selecting different date</>
        )}
        {!isLoading && machineWiseData.length > 0 && (
          <>
            {machineWiseData.map((machine) => (
              <DemographyCard
                tabChange={tabChange}
                machine={getDisplayName(machine.machine, MACHINES)}
                OEE={machine.OEE}
                partCount={machine.partCount}
                target={machine.shiftTarget}
                rejectionCount={machine.rejectionCount}
                idletime={machine.idleTime}
              />
            ))}
          </>
        )}
      </Box>
    </>
  )
}

export default MyMachineDashboard
