import React, { useState, useEffect } from 'react'
import { Typography, Stack, Box } from '@mui/material'
import StyledTable from '../../components/ReactTable/StyledTable'
import StyledTableContainer from '../../components/StyledTableContainer/StyledTableContainer'
import StyledEmptyHeader from '../../components/StyledEmptyHeader/StyledEmptyHeader'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'
import liveDataService from '../../service/liveData.service'
import { useMonthSelectorContext } from '../../context/MonthSelectorContext'
import { CSVLink } from 'react-csv'
import { styled } from '@mui/material'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'

const PartWiseMonthly = () => {
  const { selectedDate } = useMonthSelectorContext()
  const [partNumbers, setPartNumbers] = useState([])
  const [workOrderNumbers, setWorkOrderNumbers] = useState([])
  const [operationTypes, setOperationTypes] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const [error, setError] = useState(null)
  const [selectedPartNumber, setSelectedPartNumber] = useState({
    label: 'All part family',
    value: 'all'
  })
  const [selectedOperationType, setSelectedOperationType] = useState({
    label: 'All part types',
    value: 'all'
  })
  const [selectedWorkOrderNumber, setSelectedWorkOrderNumbers] = useState({
    label: 'All PO numbers',
    value: 'all'
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const response = await liveDataService.getPartWiseMonthly({
          date: selectedDate
        })
        // console.log(response)
        const data = response.data.data

        // ADDING FILTER OPTION
        const uniquePartNumbers = [
          {
            label: 'All part family',
            value: 'all'
          }
        ]
        const uniquePartTypes = [
          {
            label: 'All part types',
            value: 'all'
          }
        ]
        const uniqueWorkOrderNumber = [
          {
            label: 'All PO numbers',
            value: 'all'
          }
        ]

        data.forEach((item) => {
          if (!uniquePartNumbers.find((part) => part.value === item.partName)) {
            uniquePartNumbers.push({
              label: item.partName,
              value: item.partName
            })
          }
          if (!uniquePartTypes.find((type) => type.value === item.partType)) {
            uniquePartTypes.push({
              label: item.partType,
              value: item.partType
            })
          }
          if (
            !uniqueWorkOrderNumber.find(
              (work) => work.value === item.workOrderNum
            )
          ) {
            uniqueWorkOrderNumber.push({
              label: item.workOrderNum,
              value: item.workOrderNum
            })
          }
        })

        setData(data)
        setPartNumbers(uniquePartNumbers)
        setOperationTypes(uniquePartTypes)
        setWorkOrderNumbers(uniqueWorkOrderNumber)
      } catch (error) {
        setError(error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [selectedDate])

  let formattedData = [...data] // Corrected the assignment here

  const searchObj1 = {
    ...(selectedPartNumber &&
      selectedPartNumber.value &&
      selectedPartNumber.value !== 'all' && {
        partName: selectedPartNumber.value
      }),
    ...(selectedOperationType &&
      selectedOperationType.value &&
      selectedOperationType.value !== 'all' && {
        partType: selectedOperationType.value
      }),
    ...(selectedWorkOrderNumber &&
      selectedWorkOrderNumber.value &&
      selectedWorkOrderNumber.value !== 'all' && {
        workOrderNum: selectedWorkOrderNumber.value
      })
  }

  const keysToCheck = Object.keys(searchObj1)

  if (keysToCheck.length > 0) {
    formattedData = formattedData.filter((productionData) => {
      return keysToCheck.every((key) => searchObj1[key] === productionData[key])
    })
  }

  const totalPartCount = formattedData.reduce((acc, cv) => {
    const partCount = Number(cv.totalPartCount)

    if (!isNaN(partCount)) {
      return acc + partCount
    } else {
      return acc
    }
  }, 0)

  const totalIdleTime = formattedData.reduce((acc, cv) => {
    const idleTime = Number(cv.totalIdleTime)

    if (!isNaN(idleTime)) {
      return acc + idleTime
    } else {
      return acc
    }
  }, 0)

  const totalRejectionCount = formattedData.reduce((acc, cv) => {
    const rejectionCount = Number(cv.totalRejectionCount)

    if (!isNaN(rejectionCount)) {
      return acc + rejectionCount
    } else {
      return acc
    }
  }, 0)

  const LogoWrapper = styled('img')(({ theme }) => ({
    maxWidth: '100%'
  }))

  const columns = [
    { Header: 'Month', accessor: 'month' },
    { Header: 'Part family', accessor: 'partName' },
    { Header: 'Part number', accessor: 'partType' },
    { Header: 'Production order', accessor: 'workOrderNum' },
    { Header: 'PO quantity', accessor: 'quantity' },
    { Header: 'Total production qty', accessor: 'totalPartCount' },
    { Header: 'Total idletime', accessor: 'totalIdleTime' },
    { Header: 'Total rejection qty', accessor: 'totalRejectionCount' }
  ]

  const csvData = data.map((row) => ({
    Month: row.month,
    'Part family': row.partName,
    'Part number': row.partType,
    'Production order': row.workOrderNum,
    'PO quantity': row.quantity,
    'Total production qty': row.totalPartCount,
    'Part idletime': row.totalIdleTime,
    'Part rejection qty': row.totalRejectionCount
  }))

  return (
    <>
      <Stack
        sx={{
          position: 'sticky',
          top: 0,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '24px',
          padding: '16px',
          backgroundColor: '#fff',
          borderBottom: '1px solid #eeeeee'
        }}
      >
        <StyledSelectField
          label=""
          placeHolderText="Select part number"
          selectedValue={selectedPartNumber}
          selectOptions={partNumbers}
          onChangeHandler={(value) => setSelectedPartNumber(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No part found"
        />
        <StyledSelectField
          label=""
          placeHolderText="Select operation"
          selectedValue={selectedOperationType}
          selectOptions={operationTypes}
          onChangeHandler={(value) => setSelectedOperationType(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No operation found"
        />
        <StyledSelectField
          label=""
          placeHolderText="Select PO number"
          selectedValue={selectedWorkOrderNumber}
          selectOptions={workOrderNumbers}
          onChangeHandler={(value) => setSelectedWorkOrderNumbers(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No PO found"
        />
        <CSVLink
          data={csvData}
          filename={`Monthly-summary-${selectedDate}.csv`}
          style={{
            alignSelf: 'stretch',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0 8px',
            backgroundColor: '#f9fafc',
            boxShadow: '0 2px 7px 0 rgb(18 18 19 / 6%)',
            transition:
              'box-shadow 300ms ease, color 300ms ease, border-color 300ms ease',
            '&:hover': {
              borderColor: '#a8a8b5',
              boxShadow: '0 2px 12px 0 rgb(18 18 19 / 10%)'
            },
            color: '#000',
            borderRadius: '8px',
            border: '1px solid #d9d9d9',
            cursor: 'pointer',
            fontWeight: 500,
            fontSize: '16px'
          }}
        >
          Export
          <FileDownloadOutlinedIcon sx={{ marginLeft: '4px' }} />
        </CSVLink>
      </Stack>
      <Stack
        sx={{
          gap: '16px',
          padding: '16px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: '32px'
          }}
        >
          <Typography sx={{ fontWeight: 400 }}>
            Total production qty:{' '}
            <span style={{ fontWeight: 600 }}>{totalPartCount}</span>
          </Typography>
          <Typography sx={{ fontWeight: 400 }}>
            Total idletime (in min):{' '}
            <span style={{ fontWeight: 600 }}>{totalIdleTime}</span>
          </Typography>
          <Typography sx={{ fontWeight: 400 }}>
            Total rejection qty:{' '}
            <span style={{ fontWeight: 600 }}>{totalRejectionCount}</span>
          </Typography>
        </Box>
        {isLoading ? (
          <>Loading...</>
        ) : (
          <StyledTableContainer>
            {formattedData.length === 0 && (
              <StyledEmptyHeader>
                There are no parts available, try selecting a different date.
              </StyledEmptyHeader>
            )}
            {formattedData.length > 0 && (
              <StyledTable
                pagination={false}
                columns={columns}
                data={formattedData}
                hiddenColumns={[]}
              />
            )}
          </StyledTableContainer>
        )}
      </Stack>
    </>
  )
}
export default PartWiseMonthly
