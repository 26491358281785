import React from 'react'
import { styled } from '@mui/material'

import DoughnutChart from '../Charts/DonutChart/DoughnutChart'

const rejectionDataMapper = (dataObj) => {
  const chartData = []
  for (const property in dataObj) {
    chartData.push({
      label: property,
      value: dataObj[property]
    })
  }

  chartData.sort((a, b) => b.value - a.value)

  return {
    labels: chartData.map((el) => el['label']),
    values: chartData.map((el) => el['value'])
  }
}

const Container = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid lightgray',
  borderRadius: '12px'
}))

const CardHeader = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  // justifyContent: 'space-between',
  padding: '12px',
  borderBottom: '1px solid lightgray'
}))

const CardBody = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '12px'
}))

const CardFooter = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  borderTop: '1px solid lightgray',
  padding: '12px'
}))

const RejectionChartWithoutControls = ({
  rejectionData,
  selectionComponent
}) => {
  console.log('rejectionData', rejectionData)

  const chartData = rejectionDataMapper(rejectionData)

  const totalCount = chartData
    ? chartData['values'].reduce((accu, element) => accu + element, 0)
    : 0

  return (
    <Container>
      <CardHeader>
        <h3>Rejection</h3>
        <h4 style={{ marginLeft: 'auto' }}>
          Total rejection = {totalCount && totalCount && totalCount.toFixed(0)}{' '}
        </h4>
        <div style={{ width: '300px' }}>
          {selectionComponent && selectionComponent}
        </div>
      </CardHeader>
      <CardBody>
        <DoughnutChart
          chartTitle=""
          chartLabels={chartData.labels}
          dataGroup={chartData.values}
        />
      </CardBody>
    </Container>
  )
}

export default RejectionChartWithoutControls
