import React, { useState, useEffect } from 'react'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import AddIcon from '@mui/icons-material/Add'
import { Tooltip } from '@mui/material'

import StyledModal from '../../components/StyledModal/Modal'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'
import StyledTextFieldWithLabel from '../../components/StyledTextFieldWithLabel/StyledTextFieldWithLabel'
import FormFieldItem from './FormFiledItem'

import operationTypeService from '../../service/operationType.service'
import toolTypeService from '../../service/toolType.service'
import { MACHINES } from '../../utils/constant.utils'
import { PartsData, PartFormFieldsType } from '../../types/parts'
import { OptionItem } from '../../types/common'
import { showNotification } from '../../utils/toast.utils'

interface Props {
  isModalOpen: boolean
  primaryActionHandler: (partData: PartFormFieldsType) => void
  closeHandler: () => void
  defaultData?: PartsData | null
  isLoading?: boolean
}

const PartUploadModal = (props: Props) => {
  let defaultPartName = ''
  let defaultPartType: OptionItem = {
    value: 0,
    label: 'Select part family'
  }
  let defaultMachine: OptionItem = {
    value: 0,
    label: 'Select machine'
  }
  let defaultMachiningTime = '00:00:00'
  let defaultLoadUnloadTime = '00:00'
  let defaultIsActive = true
  let defaultToolsInfoMap: Record<
    number,
    { id?: number; toolNumber: number; toolTypeId: number }
  > = {}

  if (props.defaultData) {
    defaultPartName = props.defaultData.name

    defaultPartType.value = props.defaultData.type
    defaultPartType.label = props.defaultData.type

    defaultMachine.value = props.defaultData.machine
    defaultMachine.label = props.defaultData.machine.toUpperCase()

    defaultMachiningTime = props.defaultData.machiningTime
    defaultLoadUnloadTime = props.defaultData.loadUnloadTime

    defaultIsActive = props.defaultData.isActive
    defaultToolsInfoMap = props.defaultData.toolMaps
      .sort((a, b) => a.toolNumber - b.toolNumber)
      .reduce((acc: any, item: any, index: number) => {
        return {
          ...acc,
          [index + 1]: {
            id: item.id,
            toolNumber: item.toolNumber,
            toolTypeId: item.toolTypeId
          }
        }
      }, {})
  }

  const [partTypeOptions, setPartTypeOptions] = useState([])
  const [toolTypeOptions, setToolTypeOptions] = useState([])
  const [partName, setPartName] = useState(defaultPartName)
  const [partType, setPartType] = useState(defaultPartType)
  const [machine, setMachine] = useState(defaultMachine)
  const [machiningTime, setMachiningTime] = useState(defaultMachiningTime)
  const [loadUnloadTime, setLoadUnloadTime] = useState(defaultLoadUnloadTime)
  const [isActive, setIsActive] = useState(defaultIsActive)
  const [toolsInfoMap, setToolsInfoMap] = useState(defaultToolsInfoMap)
  const [isLoading, setIsLoading] = useState(false)

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const promises = [
          operationTypeService.getOperationTypes(),
          toolTypeService.getToolTypes({})
        ]

        setIsLoading(true)
        const data = await Promise.allSettled(promises)

        const operationTypeResponse = data[0] as any
        const toolTypeResponse = data[1] as any

        if (
          operationTypeResponse.status === 'fulfilled' &&
          operationTypeResponse.value.success
        ) {
          const partTypeOptions = operationTypeResponse.value.data.map(
            (item: any) => ({
              value: item.type,
              label: item.type
            })
          )
          setPartTypeOptions(partTypeOptions)
        }

        if (
          toolTypeResponse.status === 'fulfilled' &&
          toolTypeResponse.value.success
        ) {
          const toolTypeData = toolTypeResponse.value.data.map((item: any) => ({
            value: item.id,
            label: item.type
          }))

          setToolTypeOptions(toolTypeData)
        }
      } catch (error) {
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [])

  return (
    <StyledModal
      width={500}
      loading={isLoading || props.isLoading}
      isOpen={props.isModalOpen}
      title={props.defaultData ? 'Edit part' : 'Add part'}
      closeHandler={props.closeHandler}
    >
      <Stack
        spacing={2}
        direction="column"
        sx={{ marginBottom: '16px', width: '100%' }}
      >
        <StyledSelectField
          label="Part family"
          placeHolderText="Select part family"
          selectedValue={partType}
          selectOptions={partTypeOptions}
          onChangeHandler={(value: OptionItem) => {
            if (value) {
              setPartType(value)
              setPartName(`${value.label}_`)
            }
          }}
          isMulti={false}
          isSearchable={true}
          isDisabled={props.defaultData}
          noOptionsMessage="No part family found"
        />
        <StyledTextFieldWithLabel
          label="Part number"
          placeholderText="Enter part number"
          textValue={partName}
          onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value.length >= partType.value.length + 1) {
              setPartName(e.target.value)
            }
          }}
          isDisabled={false}
          type="text"
          isMargin={true}
        />

        <StyledSelectField
          label="Machine"
          placeHolderText="Select machine"
          selectedValue={machine}
          selectOptions={MACHINES}
          onChangeHandler={(value: OptionItem) => setMachine(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No machine found"
        />
        <StyledTextFieldWithLabel
          label="Machining time (mm:ss:SSS)"
          placeholderText="Enter machining time. Eg: 01:50:860"
          textValue={machiningTime}
          onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
            setMachiningTime(e.target.value)
          }
          isDisabled={false}
          type="text"
        />
        <StyledTextFieldWithLabel
          label="Load and unload time (mm:ss)"
          placeholderText="Enter load and unload time. Eg: 01:50"
          textValue={loadUnloadTime}
          onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
            setLoadUnloadTime(e.target.value)
          }
          isDisabled={false}
          type="text"
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ color: '#000' }}>Add to production list</Typography>
          <Checkbox
            sx={{ padding: 0 }}
            checked={isActive}
            onChange={(e) => setIsActive(e.target.checked)}
          />
        </Box>
        <Stack sx={{ gap: '8px' }}>
          <Typography sx={{ fontWeight: 600 }}>Tools info</Typography>
          {toolTypeOptions.length > 0 &&
            Object.values(toolsInfoMap).map((item, index) => {
              return (
                <FormFieldItem
                  key={index}
                  toolTypeOptions={toolTypeOptions}
                  toolType={item.toolTypeId}
                  toolNumber={item.toolNumber}
                  toolTypeChangeHandler={(value) => {
                    setToolsInfoMap((prev) => ({
                      ...prev,
                      [index + 1]: {
                        ...prev[index + 1],
                        toolTypeId: value
                      }
                    }))
                  }}
                  toolNumberChangeHandler={(value) => {
                    setToolsInfoMap((prev) => ({
                      ...prev,
                      [index + 1]: {
                        ...prev[index + 1],
                        toolNumber: Number(value)
                      }
                    }))
                  }}
                  fieldDeleteHandler={() => {
                    const keyNum = index + 1
                    setToolsInfoMap((prev) => {
                      delete prev[keyNum]
                      return {
                        ...prev
                      }
                    })
                  }}
                  disableDelete={Boolean(props.defaultData)}
                />
              )
            })}
          {Object.values(toolsInfoMap).length === 0 && (
            <Typography>No tools info yet</Typography>
          )}
        </Stack>
        <Stack sx={{ alignItems: 'center' }}>
          <Tooltip title={'Add tools type'} placement="left">
            <Box
              onClick={() => {
                if (!partName || !partType || !machine) {
                  showNotification(
                    'Enter part details to add tool info',
                    'error'
                  )
                  return
                }
                setToolsInfoMap((prev: any) => {
                  const keyNum = Object.values(toolsInfoMap).length + 1
                  return {
                    ...prev,
                    [keyNum]: {
                      toolNumber: keyNum,
                      toolTypeId: 0
                    }
                  }
                })
              }}
              sx={{
                display: 'grid',
                placeItems: 'center',
                width: '32px',
                height: '32px',
                borderRadius: '50%',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <AddIcon sx={{ color: '#676767', fontSize: '24px' }} />
            </Box>
          </Tooltip>
        </Stack>
      </Stack>
      <Stack
        spacing={2}
        direction="row"
        sx={{
          marginTop: '16px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button variant="outlined" onClick={props.closeHandler}>
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ color: '#fff' }}
          onClick={() =>
            props.primaryActionHandler({
              toolMaps: Object.values(toolsInfoMap),
              name: partName,
              type: partType.value,
              machine: machine.value,
              machiningTime,
              loadUnloadTime,
              isActive: true
            })
          }
        >
          {props.defaultData ? 'Update' : 'Add part'}
        </Button>
      </Stack>
    </StyledModal>
  )
}

export default PartUploadModal
