  import React from 'react'
  import { styled } from '@mui/material'
  import Box from '@mui/material/Box'
  import Switch from '@mui/material/Switch'

  import BarChart from '../Charts/BarChart/BarChart'
  import * as CHART_UTILS from '../Charts/BarChart/BarChart.constant'

  const CardHeader = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px',
    borderBottom: '1px solid lightgray'
  }))

  const SelectBox = styled('select')(() => ({
    borderRadius: '4px',
    padding: '4px 8px'
  }))

  const OptionBox = styled('option')(() => ({
    padding: '4px 8px'
  }))

  const shiftMap = {
    s1: { value: 's1', label: 'Shift-1', chartLegend: 'S1' },
    s2: { value: 's2', label: 'Shift-2', chartLegend: 'S2' },
    s3: { value: 's3', label: 'Shift-3', chartLegend: 'S3' },
    day: { value: 'day', label: 'Day', chartLegend: 'Day' }
  }

  const ProductionTrend = ({ productionTrend }) => {
    const [isCombined, setIsCombined] = React.useState(false)
    const [selectedShift, setSelectedShift] = React.useState(shiftMap.day.value)

    const chartTitle = 'Production trend'
    const chartLabels = productionTrend
      ? productionTrend.map((el) => el['date'])
      : []

    let achievedDay = []
    let achievedNight = []
    let achievedS3 = []
    let achievedTotal = []

    let targetDay = []
    let targetNight = []
    let targetS3 = []
    let targetTotal = []

    const prepareChartData = (data) => {
      achievedDay = data.map((el) => el['s1Achieved'])
      achievedNight = data.map((el) => el['s2Achieved'])
      achievedS3 = data.map((el) => el['s3Achieved'])
      achievedTotal = data.map((el) => el['dayAchieved'])

      targetDay = data.map((el) => el['s1Target'])
      targetNight = data.map((el) => el['s2Target'])
      targetS3 = data.map((el) => el['s3Target'])
      targetTotal = data.map((el) => el['dayTarget'])
    }

    prepareChartData(productionTrend)

    const combinedDataGroup = [
      {
        ...CHART_UTILS.BAR,
        label: 'S1 Achieved',
        data: achievedDay,
        backgroundColor: '#7afade',
        borderColor: '#04b890'
      },
      {
        ...CHART_UTILS.BAR,
        label: 'S1 Target',
        data: targetDay,
        type: 'line',
        fill: false,
        backgroundColor: '#7afade',
        borderColor: '#04b890',
        borderWidth: 1,
        tension: 0,
        pointRadius: 2,
        pointHoverRadius: 5
      },
      {
        ...CHART_UTILS.BAR,
        label: 'S2 Achieved',
        data: achievedNight,
        backgroundColor: '#f5ae67',
        borderColor: '#b55d05'
      },
      {
        ...CHART_UTILS.BAR,
        label: 'S2 Target',
        data: targetNight,
        type: 'line',
        fill: false,
        backgroundColor: '#f5ae67',
        borderColor: '#b55d05',
        borderWidth: 1,
        tension: 0,
        pointRadius: 2,
        pointHoverRadius: 5
      },
      {
        ...CHART_UTILS.BAR,
        label: 'S3 Achieved',
        data: achievedS3,
        backgroundColor: '#f5ae67',
        borderColor: '#b55d05'
      },
      {
        ...CHART_UTILS.BAR,
        label: 'S3 Target',
        data: targetS3,
        type: 'line',
        fill: false,
        backgroundColor: '#f5ae67',
        borderColor: '#b55d05',
        borderWidth: 1,
        tension: 0,
        pointRadius: 2,
        pointHoverRadius: 5
      },
      {
        ...CHART_UTILS.BAR,
        label: 'Day Achieved',
        data: achievedTotal,
        backgroundColor: '#fa9ba6',
        borderColor: '#fa465b'
      },
      {
        ...CHART_UTILS.BAR,
        label: 'Day Target',
        data: targetTotal,
        type: 'line',
        fill: false,
        backgroundColor: '#fa9ba6',
        borderColor: '#fa465b',
        borderWidth: 1,
        tension: 0,
        pointRadius: 2,
        pointHoverRadius: 5
      }
    ]

    const dataGroup = productionTrend
      ? [
          {
            ...CHART_UTILS.BAR,
            label: `${shiftMap[selectedShift].chartLegend} Achieved`,
            data: productionTrend.map((el) => el[`${selectedShift}Achieved`]),
            backgroundColor: '#7afade',
            borderColor: '#04b890'
          },
          {
            ...CHART_UTILS.BAR,
            label: `${shiftMap[selectedShift].chartLegend} Target`,
            data: productionTrend.map((el) => el[`${selectedShift}Target`]),
            type: 'line',
            fill: false,
            backgroundColor: '#7afade',
            borderColor: '#04b890',
            borderWidth: 1,
            tension: 0,
            pointRadius: 2,
            pointHoverRadius: 5
          }
        ]
      : []

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          border: '1px solid gray',
          borderRadius: '12px'
        }}
      >
        <CardHeader>
          <h3>{chartTitle}</h3>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              alignSelf: 'flex-end'
            }}
          >
            <SelectBox
              disabled={isCombined}
              value={selectedShift}
              onChange={(event) => setSelectedShift(event.target.value)}
            >
              {Object.values(shiftMap).map((item) => (
                <OptionBox value={item.value}>{item.label}</OptionBox>
              ))}
            </SelectBox>
          </Box>
        </CardHeader>
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              padding: '0 12px'
            }}
          >
            <label style={{ marginLeft: 'auto' }}>Combined</label>
            <Switch
              checked={isCombined}
              onChange={(event) => setIsCombined(event.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>
          <BarChart
            chartTitle={chartTitle}
            chartLabels={chartLabels}
            dataGroup={isCombined ? combinedDataGroup : dataGroup}
            xLabel="DAYS"
            yLabel="Achieved quantity"
          />
        </Box>
      </Box>
    )
  }

  export default ProductionTrend
