import axios from 'axios'

const hmiUpdateService = {
  updateHmiParameter: async (path, params) => {
    try {
      const response = await axios.patch(path, {
        params: params
      })
      return response.data
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  resetShift: async (machine, clearShiftData) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOGGER_SERVICE_URL}/v1/reset-shift/${machine}`,
        {
          params: {
            clearShiftData
          }
        }
      )
      return response.data
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  masterShiftReset: async (clearShiftData) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOGGER_SERVICE_URL}/v1/reset-shift`,
        {
          params: {
            clearShiftData
          }
        }
      )
      return response.data
    } catch (err) {
      console.log(err)
      return err.response
    }
  }
}

export default hmiUpdateService
