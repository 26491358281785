import axios from 'axios'

const axiosObj = axios.create({
  baseURL: `${process.env.REACT_APP_ADMIN_BASE_URL}/v1`,
  headers: {
    'Content-Type': 'application/json'
  },
  responseType: 'json'
})

const operationTypeService = {
  getOperationTypes: async () => {
    try {
      const response = await axiosObj.get('/operation-type')

      return response.data
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  addOperationType: async (data) => {
    try {
      const response = await axiosObj.post('/operation-type', data)
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  editOperationType: async (id, data) => {
    try {
      const response = await axiosObj.put(`/operation-type/${id}`, data)
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  deleteOperationType: async (id, data) => {
    try {
      const response = await axiosObj.delete(`/operation-type/${id}`, data)
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  }
}

export default operationTypeService
