//
import React, { useState, useEffect } from 'react'
import Stack from '@mui/material/Stack'
import { Typography, Box } from '@mui/material'

//components
import StyledTable from '../../components/ReactTable/StyledTable'
import StyledTableContainer from '../../components/StyledTableContainer/StyledTableContainer'
import StyledEmptyHeader from '../../components/StyledEmptyHeader/StyledEmptyHeader'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'

//services
import { MACHINES } from '../../utils/constant.utils'
import liveDataService from '../../service/liveData.service'
import { getDisplayName } from '../../utils/common.utils'
import { useDateSelectorContext } from '../../context/DateSelectorContext'
import { Parser } from '@json2csv/plainjs'

const machineOptions = [...MACHINES]

const headers = [
  { label: 'Date', value: 'date' },
  { label: 'Shift', value: 'shift' },
  { label: 'Machine', value: 'machine' },
  { label: 'Production order', value: 'workOrderNum' },
  { label: 'Part family', value: 'partName' },
  { label: 'Part number', value: 'partType' },
  { label: 'Rejection reason', value: 'reason' },
  { label: 'Total production qty', value: 'partCount' },
  { label: 'Rejection quantity', value: 'rejectionQuantity' }
]

const RejectionLossDailyWise = () => {
  //context
  const { selectedDate } = useDateSelectorContext()

  //local
  const [csvData, setCsvData] = useState([])
  const [data, setData] = useState([])
  const [data1, setData1] = useState([])
  const [rejectionLossRows, setRejectionLossRows] = useState([])
  const [machine, setMachine] = useState(machineOptions[0])
  const [availableShifts, setAvailableShifts] = useState([])
  const [selectedShift, setSelectedShift] = useState({
    label: 'All shifts',
    value: 'all'
  })
  const [availableRejectionReasons, setAvailableRejectionReasons] = useState([])
  const [selectedRejectionReason, setSelectedRejectionReason] = useState({
    label: 'All rejection reasons',
    value: 'all'
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (machine === null) return
    const fetchRejectionLossRecords = async () => {
      const params = {}

      if (selectedDate) {
        params.date = selectedDate
      }

      if (machine.value !== 0) {
        params.machine = machine.value
      }

      try {
        setIsLoading(true)
        const RejectionLossResponse =
          await liveDataService.getRejectionLossDailyWise(params)
        // console.log(RejectionLossResponse)
        const data = RejectionLossResponse.data.data
        const availableShifts = []
        const availableRejectionReasonsFromAPI = []
        const partsData = data.map((data, i) => {
          const shiftValue = `S${data.shift}`

          if (!availableShifts.includes(shiftValue)) {
            availableShifts.push(shiftValue)
          }
          if (!availableRejectionReasonsFromAPI.includes(data.reason)) {
            availableRejectionReasonsFromAPI.push(data.reason)
          }

        console.log("reasonRejection", availableRejectionReasonsFromAPI)

          return {
            date: data.date,
            machine: data.machine,
            shift: shiftValue,
            workOrderNum: data.workOrderNum,
            partName: data.partName,
            partType: data.partType,
            reason: data.reason,
            partCount: data.partCount,
            rejectionQuantity: data.rejectionQuantity
          }
        })

        setAvailableShifts([
          {
            label: 'All shifts',
            value: 'all'
          },
          ...availableShifts.map((shift) => {
            return { label: shift, value: shift }
          })
        ])
        setAvailableRejectionReasons([
          {
            label: 'All rejection reason',
            value: 'all'
          },
          ...availableRejectionReasonsFromAPI.map((reason) => {
            return { label: reason, value: reason }
          })
        ])
        setData(data)
        setRejectionLossRows(partsData)
      } catch (err) {
        console.log(err)
        setRejectionLossRows([])
      } finally {
        setIsLoading(false)
      }
    }
    fetchRejectionLossRecords()
  }, [selectedDate, machine])

  // let formattedData = [...data]
  // /1. Create search object
  const searchObj = {
    ...(selectedShift &&
      selectedShift.value &&
      selectedShift.value !== 'all' && {
        shift: selectedShift.value
      }),
    ...(machine &&
      (machine.value !== 'all' || machine.value === 0) && {
        machine: machine.value
      }),
    ...(selectedRejectionReason &&
      selectedRejectionReason.value &&
      selectedRejectionReason.value !== 'all' && {
        reason: selectedRejectionReason.value
      })
  }

  //2. Extract the keys into an array
  const keysToChecked = Object.keys(searchObj)

  //3. filter
  let filteredRows = rejectionLossRows
  if (keysToChecked.length > 0) {
    filteredRows = rejectionLossRows.filter((row) => {
      return keysToChecked.every((key) => searchObj[key] === row[key])
    })
  }

  const totalRejectionQuantity = filteredRows.reduce((acc, cv) => {
    const rejectionCount = Number(cv.rejectionQuantity)

    if (!isNaN(rejectionCount)) {
      return acc + rejectionCount
    } else {
      return acc
    }
  }, 0)

  const rejectionLossColumns = [
    {
      Header: 'Machine',
      accessor: 'machine',
      Cell: ({ cell: { row } }) => {
        return getDisplayName(row.values.machine, MACHINES)
      }
    },
    {
      Header: 'Shift',
      accessor: 'shift'
    },
    {
      Header: 'Production order',
      accessor: 'workOrderNum'
    },

    {
      Header: 'Product family',
      accessor: 'partName'
    },
    {
      Header: 'Part number',
      accessor: 'partType'
    },
    {
      Header: 'Rejection reason',
      accessor: 'reason'
    },
    {
      Header: 'Production quantity',
      accessor: 'partCount'
    },
    {
      Header: 'Rejection quantity',
      accessor: 'rejectionQuantity'
    }
  ]

  const csvDownload = async () => {
    try {
      setIsLoading(true)
      const response = await liveDataService.getRejectionLossMonthWise({
        date: selectedDate
      })
      // console.log(response)
      const data = response.data.data

      const parser = new Parser({
        fields: [...headers]
      })
      const formattedCSV = parser.parse([...data])
      const blob = new Blob([formattedCSV], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Rejection-loss-${selectedDate}.csv`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Stack
        sx={{
          position: 'sticky',
          top: 0,
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '24px',
          padding: '16px',
          backgroundColor: '#fff',
          borderBottom: '1px solid #eeeeee'
        }}
      >
        <StyledSelectField
          label=""
          placeHolderText="Select shift"
          selectedValue={selectedShift}
          selectOptions={availableShifts}
          onChangeHandler={(value) => setSelectedShift(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No shift found"
        />
        <StyledSelectField
          label=""
          placeHolderText="Select machine"
          selectedValue={machine}
          selectOptions={machineOptions}
          onChangeHandler={(value) => setMachine(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No machine found"
        />
        <StyledSelectField
          label=""
          placeHolderText="Select rejection reason"
          selectedValue={selectedRejectionReason}
          selectOptions={availableRejectionReasons}
          onChangeHandler={(value) => setSelectedRejectionReason(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No rejection reason found"
        />
        <button
          onClick={csvDownload}
          style={{
            position: 'relative',
            width: '150px',
            height: '50px',
            display: 'flex',
            padding: '16px',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#fff',
            boxShadow: '0 2px 7px 0 rgb(18 18 19 / 6%)',
            transition:
              'box-shadow 300ms ease, color 300ms ease, border-color 300ms ease',
            '&:hover': {
              borderColor: '#a8a8b5',
              boxShadow: '0 2px 12px 0 rgb(18 18 19 / 10%)'
            },
            color: '#000',
            borderRadius: '8px',
            border: '1px solid #d9d9d9',
            cursor: 'pointer',
            fontWeight: 600,
            fontSize: '16px',
            '&:hover': { backgroundColor: '#fafafa' }
          }}
        >
          Export
          <FileDownloadOutlinedIcon sx={{ marginLeft: '4px' }} />
        </button>
      </Stack>
      <Stack sx={{ gap: '16px', padding: '16px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: '32px'
          }}
        >
          <Typography sx={{ fontWeight: 400 }}>
            Total rejection qty:{' '}
            <span style={{ fontWeight: 600 }}>{totalRejectionQuantity}</span>
          </Typography>
        </Box>

        {isLoading && <div>Loading...</div>}
        {!isLoading && filteredRows.length === 0 && (
          <StyledEmptyHeader>
            There are no rejection loss reasons records for the selected date
          </StyledEmptyHeader>
        )}
        {!isLoading && filteredRows.length > 0 && (
          <StyledTableContainer>
            <StyledTable
              pagination={false}
              columns={rejectionLossColumns}
              data={filteredRows}
              hiddenColumns={['machine']}
            />
          </StyledTableContainer>
        )}
      </Stack>
    </>
  )
}

export default RejectionLossDailyWise
