import axios from 'axios'

const axiosObj = axios.create({
  baseURL: `${process.env.REACT_APP_ADMIN_BASE_URL}/v1`,
  headers: {
    'Content-Type': 'application/json'
  },
  responseType: 'json'
})

const partToolInfoService = {
  getPartToolsInfo: async (data) => {
    try {
      const response = await axiosObj.get('/part-tools')

      return response.data
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  getPartToolsInfoByPartId: async (partId) => {
    try {
      const response = await axiosObj.get(`/part-tools/${partId}`)

      return response.data
    } catch (err) {
      console.log(err)
      return err.response
    }
  }
}

export default partToolInfoService
