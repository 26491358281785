import React from 'react'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

export const MoreInfoIcon = (props) => {
  const { onClickHandler } = props
  return (
    <InfoOutlinedIcon
      sx={{ cursor: 'pointer', color: 'primary.main' }}
      onClick={onClickHandler}
    />
  )
}

export const EditIcon = (props) => {
  const { onClickHandler } = props
  return (
    <EditOutlinedIcon
      sx={{ cursor: 'pointer', color: 'primary.main' }}
      onClick={onClickHandler}
    />
  )
}

export const DeleteIcon = (props) => {
  const { onClickHandler } = props
  return (
    <DeleteOutlinedIcon
      sx={{ cursor: 'pointer', color: 'primary.main' }}
      onClick={onClickHandler}
    />
  )
}
