import React from 'react'
import { Box, Button, Typography, Stack } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'

import StyledModal from '../../components/StyledModal/Modal'
import FormFieldItemToolId from './FormFieldItemToolId'

import { ToolsApiData } from '../../types/tools'
import { IProductionOrderApiNew } from '../../types/productionOrder'
import toolService from '../../service/toolLife.service'
import { showNotification } from '../../utils/toast.utils'
import productionOrderService from '../../service/productionOrder.service'

interface Props {
  isModalOpen: boolean
  closeHandler: () => void
  defaultData: IProductionOrderApiNew
}

const ProductionOrderPreviewModal = (props: Props) => {
  const [tools, setTools] = React.useState<ToolsApiData[]>([])
  const [isLoading, setIsLoading] = React.useState(false)
  const [selectedToolToChange, setSelectedToolToChange] = React.useState<
    number | null
  >(null)
  const [toolsInfoMap, setToolsInfoMap] = React.useState<{
    workOrderNumber: number
    toolNumber: number
    toolTypeId: number
    toolType: string
    toolId: string | null
    reason: string | null
  } | null>(null)

  React.useEffect(() => {
    const fetchTools = async () => {
      setIsLoading(true)
      const toolsResponse = await toolService.getTools({})

      if (toolsResponse.success) {
        setTools(toolsResponse.data)
      } else {
        setTools([])
      }

      setIsLoading(false)
    }
    fetchTools()
  }, [])

  const addToolToProductionOrderToolMap = async () => {
    console.log(toolsInfoMap)
    if (!toolsInfoMap) {
      showNotification('Please refresh the page to continue', 'error')
      return
    }
    if (!toolsInfoMap.toolId || !toolsInfoMap.reason) {
      showNotification('Tool ID and reason are required', 'error')
      return
    }

    setIsLoading(true)
    const response =
      await productionOrderService.addToolToProductionOrderToolMap({
        ...toolsInfoMap,
        toolId: toolsInfoMap.toolId,
        reason: toolsInfoMap.reason
      })
    if (response.success) {
      showNotification(`Tool changed successfully`, 'success')
    } else {
    }
    setSelectedToolToChange(null)
    setToolsInfoMap(null)
    setIsLoading(false)
  }

  const groupedData: Record<
    number,
    {
      toolTypeId: number
      toolNumber: number
      toolType: string
      toolData: IProductionOrderApiNew['toolMaps']
    }
  > = {}

  props.defaultData.toolMaps.forEach((toolInfo) => {
    if (!groupedData[toolInfo.toolNumber]) {
      groupedData[toolInfo.toolNumber] = {
        toolTypeId: toolInfo.toolTypeId,
        toolNumber: toolInfo.toolNumber,
        toolType: toolInfo.toolType.type,
        toolData: []
      }
    }
    groupedData[toolInfo.toolNumber].toolData.push(toolInfo)
  })
  console.log({ groupedData: Object.entries(groupedData) })

  return (
    <StyledModal
      width={500}
      loading={isLoading}
      isOpen={props.isModalOpen}
      title="Tools info"
      closeHandler={() => props.closeHandler()}
    >
      <Box>
        <Typography> PO number: {props.defaultData.workOrderNum}</Typography>
        <Typography> Part number: {props.defaultData.part.type}</Typography>
      </Box>
      {Object.entries(groupedData).map(([toolNumber, data]) => (
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            border: '1px solid gray',
            padding: '8px',
            borderRadius: '4px'
          }}
        >
          {selectedToolToChange === data.toolTypeId ? (
            <Stack sx={{ gap: '8px' }}>
              <FormFieldItemToolId
                isToolChangeFlow={true}
                toolId={toolsInfoMap ? toolsInfoMap.toolId : null}
                toolIdOptions={tools}
                toolType={data.toolType}
                toolTypeId={data.toolTypeId}
                toolNumber={data.toolNumber}
                reason={toolsInfoMap ? toolsInfoMap.reason : null}
                toolIdChangeHandler={(value) => {
                  setToolsInfoMap((prev) => ({
                    workOrderNumber: props.defaultData.workOrderNum,
                    toolNumber: data.toolNumber,
                    toolTypeId: data.toolTypeId,
                    toolType: data.toolType,
                    toolId: value,
                    reason: prev ? prev.reason : null
                  }))
                }}
                reasonChangeHandler={(value) => {
                  setToolsInfoMap((prev) => ({
                    workOrderNumber: props.defaultData.workOrderNum,
                    toolNumber: data.toolNumber,
                    toolTypeId: data.toolTypeId,
                    toolType: data.toolType,
                    reason: value,
                    toolId: prev ? prev.toolId : null
                  }))
                }}
              />
              <Box
                sx={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}
              >
                <Button
                  size="small"
                  variant="contained"
                  disabled={
                    toolsInfoMap && toolsInfoMap.toolTypeId === data.toolTypeId
                      ? toolsInfoMap.toolId === null ||
                        toolsInfoMap.reason === null
                      : true
                  }
                  sx={{
                    color: '#fff',
                    textTransform: 'none',
                    borderRadius: '4px'
                  }}
                  onClick={addToolToProductionOrderToolMap}
                >
                  Save
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    textTransform: 'none',
                    borderRadius: '4px'
                  }}
                  onClick={() => {
                    setSelectedToolToChange(null)
                    setToolsInfoMap(null)
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Stack>
          ) : (
            <>
              <Stack sx={{ gap: '8px' }}>
                {data.toolData.map((tool, index) => (
                  <Stack sx={{ gap: '4px' }}>
                    {index === 0 && (
                      <Typography>
                        Tool {tool.toolNumber}: ({tool.toolType.type} -{' '}
                        {tool.tool.toolSize})
                      </Typography>
                    )}

                    <Typography>
                      Tool count: {tool.toolChangeCounter}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: '8px' }}>
                      <Typography
                        sx={{
                          ...(tool.isActive &&
                            data.toolData.length > 1 && {
                              border: '0.5px solid green',
                              padding: '2px',
                              borderRadius: '2px'
                            })
                        }}
                      >
                        Tool ID: {tool.toolId}
                      </Typography>
                      {tool.isActive && data.toolData.length > 1 && (
                        <CheckIcon sx={{ color: 'green' }} />
                      )}
                    </Box>
                    <Typography> Tool life: {tool.tool.toolLife}</Typography>
                    <Typography> Reason: {tool.reason}</Typography>
                  </Stack>
                ))}
              </Stack>
              <Button
                size="small"
                variant="outlined"
                sx={{
                  position: 'absolute',
                  right: '8px',
                  bottom: '8px',
                  textTransform: 'none',
                  borderRadius: '4px'
                }}
                onClick={() => setSelectedToolToChange(data.toolTypeId)}
              >
                Change tool
              </Button>
            </>
          )}
        </Box>
      ))}
    </StyledModal>
  )
}

export default ProductionOrderPreviewModal
