import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

//components
import StyledTable from '../../components/ReactTable/StyledTable'
import StyledTableContainer from '../../components/StyledTableContainer/StyledTableContainer'
import StyledEmptyHeader from '../../components/StyledEmptyHeader/StyledEmptyHeader'
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal'
import AddToolTypeModal from './AddToolTypeModal'

//assets
import { DeleteIcon, EditIcon } from '../../icons/Icons'

//utils
import { showNotification } from '../../utils/toast.utils'

//services
import toolTypeService from '../../service/toolType.service'

const FilterContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-between',
  alignItems: 'center',
  columnGap: '16px'
}))

const ToolType = () => {
  const [toolTypes, setToolTypes] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [openAddModal, setOpenAddModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  console.log(selectedItem)

  const fetchToolTypes = async () => {
    try {
      setIsLoading(true)
      const response = await toolTypeService.getToolTypes()

      if (response.success) {
        setToolTypes(
          response.data.map((item, index) => ({
            index: index + 1,
            ...item
          }))
        )
      }
    } catch (err) {
      console.log(err)
      setToolTypes([])
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchToolTypes()
  }, [])

  const addToolType = async (toolType) => {
    if (!toolType) {
      showNotification('Tool type is required', 'error')
      return
    }

    const data = {
      type: toolType
    }

    try {
      setIsLoading(true)
      const response = await toolTypeService.addToolType(data)
      if (response.data.success) {
        showNotification(`Tool type added successfully`, 'success')
      } else {
        showNotification(response.data.message, 'error')
      }
      setOpenAddModal(false)
      fetchToolTypes()
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const editToolType = async (id, toolType) => {
    if (!toolType) {
      showNotification('Tool type is required', 'error')
      return
    }

    try {
      setIsLoading(true)
      const response = await toolTypeService.editToolType(id, {
        type: toolType
      })
      if (response.status === 200) {
        showNotification(`Tool type updated successfully`, 'success')
      } else {
        showNotification(response.data.message, 'error')
      }
      setOpenAddModal(false)
      fetchToolTypes()
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
      setIsEditMode(false)
    }
  }

  const deleteToolType = async (toolId) => {
    if (!toolId) {
      showNotification('tool id is required', 'error')
      return
    }

    try {
      setIsLoading(true)
      const response = await toolTypeService.deleteToolType(toolId)
      if (response.data.success) {
        showNotification(`Tool type deleted successfully`, 'success')
      } else {
        showNotification(response.data.message, 'error')
      }
      fetchToolTypes()
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const toolColumns = [
    {
      Header: 'S.No',
      accessor: 'index'
    },
    {
      Header: 'Id',
      accessor: 'id'
    },
    {
      Header: 'Type',
      accessor: 'type'
    },
    {
      Header: 'Actions',
      Cell: ({ cell: { row } }) => {
        return (
          <div
            style={{ display: 'flex', gap: '8px', justifyContent: 'center' }}
          >
            <EditIcon
              onClickHandler={() => {
                setIsEditMode(true)
                setSelectedItem({ ...row.values })
                setOpenAddModal(true)
              }}
            />
            <DeleteIcon
              onClickHandler={() => {
                setSelectedItem({ ...row.values })
                setOpenDeleteModal(true)
              }}
            />
          </div>
        )
      }
    }
  ]

  return (
    <>
      {/* <Stack
        sx={{
          position: 'sticky',
          top: 0,
          width: '100%',
          flexDirection: 'column',
          gap: '12px',
          borderBottom: '1px solid #eeeeee',
          backgroundColor: '#fff',
          padding: '12px 16px',
          zIndex: 100
        }}
      >
        <FilterContainer>
          <Button
            variant="contained"
            sx={{
              textTransform: 'none',
              color: '#fff'
            }}
            onClick={() => {
              setIsEditMode(false)
              setSelectedItem({})
              setOpenAddModal(true)
            }}
          >
            Add tool type
          </Button>
        </FilterContainer>
      </Stack> */}
      <Box sx={{ padding: '16px' }}>
      <StyledTableContainer>
        {isLoading && <StyledEmptyHeader>Loading...</StyledEmptyHeader>}
        {!isLoading && toolTypes.length === 0 && (
          <StyledEmptyHeader>There are no tool types</StyledEmptyHeader>
        )}
        {!isLoading && toolTypes.length > 0 && (
          <StyledTable
            pagination={false}
            columns={toolColumns}
            data={toolTypes}
            hiddenColumns={['id']}
          />
        )}
      </StyledTableContainer>
      </Box>
      {openAddModal && (
        <AddToolTypeModal
          closeHandler={() => setOpenAddModal(false)}
          isModalOpen={openAddModal}
          addTool={addToolType}
          editTool={editToolType}
          isEditMode={isEditMode}
          selectedTool={selectedItem}
        />
      )}
      {openDeleteModal && (
        <ConfirmationModal
          isModalOpen={openDeleteModal}
          description={`Do you want to delete the following operation type ${selectedItem.type}`}
          buttonTitle="Delete"
          clickHandler={() => deleteToolType(selectedItem.id)}
          closeHandler={() => setOpenDeleteModal(false)}
        />
      )}
    </>
  )
}

export default ToolType
