import React from 'react'
import { ExpandMore } from '@mui/icons-material'
import { Stack, Box, Typography, IconButton } from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { OptionItem } from '../../types/common'

import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'
import StyledTextFieldWithLabel from '../../components/StyledTextFieldWithLabel/StyledTextFieldWithLabel'

const FormFieldItem = ({
  toolNumber,
  toolType,
  toolTypeOptions,
  toolTypeChangeHandler,
  toolNumberChangeHandler,
  fieldDeleteHandler,
  disableDelete
}: {
  toolTypeOptions: { label: string; value: number }[]
  toolNumber: number
  toolType: number
  toolTypeChangeHandler: (value: number) => void
  toolNumberChangeHandler: (value: string) => void
  fieldDeleteHandler: () => void
  disableDelete: boolean
}) => {
  const [showDetails, setShowDetails] = React.useState(true)
  const toggleShowDetails = () => {
    setShowDetails((prev) => !prev)
  }
  return (
    <Stack
      sx={{
        border: `1px solid gray`,
        borderRadius: '4px',
        padding: showDetails ? '8px 16px' : '0px'
      }}
    >
      <Box
        onClick={toggleShowDetails}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: showDetails ? '0px' : '6px 12px',
          cursor: 'pointer'
        }}
      >
        <Typography sx={{ color: '#000' }}>Tool {toolNumber}</Typography>
        <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          {!disableDelete && (
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation()
                fieldDeleteHandler()
              }}
            >
              <DeleteOutlineIcon fontSize="small" />
            </IconButton>
          )}
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation()
              toggleShowDetails()
            }}
          >
            <ExpandMore
              fontSize="small"
              sx={{
                transform: showDetails ? 'rotate(180deg)' : 'none'
              }}
            />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          height: showDetails ? 'auto' : 0,
          ...(!showDetails && {
            overflow: 'hidden'
          })
        }}
      >
        <Stack sx={{ flex: 1, gap: '8px' }}>
          <StyledTextFieldWithLabel
            label="Tool number"
            placeholderText="Enter tool number"
            textValue={toolNumber}
            onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
              toolNumberChangeHandler(e.target.value)
            }
            isDisabled={false}
            type="number"
          />
          <StyledSelectField
            label="Tool type"
            placeHolderText="Select tool type"
            selectedValue={
              toolTypeOptions.find((item) => item.value === toolType) || {
                label: 'Select tool type',
                value: 0
              }
            }
            selectOptions={toolTypeOptions}
            onChangeHandler={(selectedOption: OptionItem) =>
              toolTypeChangeHandler(selectedOption.value)
            }
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No tool type found"
          />
        </Stack>
      </Box>
    </Stack>
  )
}

export default FormFieldItem
