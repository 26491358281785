import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

//components
import StyledPageContainer from '../../components/StyledPageContainer/StyledPageContainer'
import UpdateHMI from './UpdateHMI'
import ShiftReset from './ShiftReset'

const hmiUpdatePageTabs = {
  //PRODUCTION_LIST: { name: 'Active production list', value: 'production-list' },
  //EMPLOYEE_LIST: { name: 'Active employee list', value: 'employee-list' },
  UPDATE_HMI: { name: 'Update HMI', value: 'update-hmi' },
  SHIFT_RESET: { name: 'Shift reset', value: 'shift-reset' }
}

const TabsContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '8px 24px 0 0',
  borderBottom: `1px solid #eeeeee`
}))

const Tab = styled('div')<{ isActive: boolean }>(({ theme, isActive }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '16px',
  minWidth: 'fit-content',
  padding: '8px 20px',
  color: isActive ? theme.palette.primary.main : '#ccccd7',
  cursor: 'pointer',
  borderBottom: isActive
    ? `1px solid ${theme.palette.primary.main}`
    : '1px solid transparent',
  '&:hover': {
    color: `${theme.palette.primary.main}`
  }
}))

const Updates = () => {
  //default values
  let defaultTab = hmiUpdatePageTabs.UPDATE_HMI.value

  //hooks
  const navigate = useNavigate()
  const location = useLocation()

  //extract category from URL
  const params = new URLSearchParams(location.search)
  const tab = params.get('tab')

  if (tab) {
    defaultTab = tab
  }

  //component state
  const [activeSubTab, setActiveSubTab] = useState(defaultTab)

  useEffect(() => {
    navigate(`?tab=${activeSubTab}`)
  }, [activeSubTab])

  const handleSubTabChange = (tab: string) => {
    setActiveSubTab(tab)
  }

  return (
    <StyledPageContainer>
      <TabsContainer>
        {React.Children.toArray(
          Object.values(hmiUpdatePageTabs).map((tab) => (
            <Tab
              isActive={activeSubTab === tab.value}
              onClick={() => handleSubTabChange(tab.value)}
            >
              {tab.name}
            </Tab>
          ))
        )}
      </TabsContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 49.6px)',
          overflowY: 'auto',
          padding: '16px'
        }}
      >
        {activeSubTab === hmiUpdatePageTabs.UPDATE_HMI.value && <UpdateHMI />}
        {activeSubTab === hmiUpdatePageTabs.SHIFT_RESET.value && <ShiftReset />}
      </Box>
    </StyledPageContainer>
  )
}

export default Updates
