import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import CircularProgress from '@mui/material/CircularProgress'

import StyledModal from '../../components/StyledModal/StyledModal'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'
import StyledTextFieldWithLabel from '../../components/StyledTextFieldWithLabel/StyledTextFieldWithLabel'
import { showNotification } from '../../utils/toast.utils'

import operationTypeService from '../../service/operationType.service'
import rejectionReasonMapService from '../../service/rejectionReasonMap.service'

const Table = styled('table')(() => ({
  borderCollapse: 'collapse',
  width: '100%'
}))

const TableRow = styled('tr')(() => ({}))

const TableHead = styled('th')(() => ({
  textAlign: 'left',
  padding: '8px',
  border: '1px solid #bbbbbb',
  backgroundColor: '#dfdfdf'
}))

const TableCell = styled('td')(() => ({
  border: '1px solid #dddddd',
  textAlign: 'left',
  padding: '8px',
  '&:first-child': {
    borderBottomLeftRadius: '8px'
  },
  '&:last-child': {
    borderBottomRightRadius: '8px'
  }
}))

const Editable = (props) => {
  const [isEditEnabled, setIsEditEnabled] = useState(false)
  const [showActions, setShowActions] = useState(false)
  const [inputValue, setInputValue] = useState(props.reason)

  return (
    <div>
      {isEditEnabled ? (
        <div style={{ display: 'flex', gap: '4px' }}>
          <input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            style={{
              width: '100%',
              padding: '4px',
              borderRadius: '4px',
              outline: 'none',
              border: '1px solid gray'
            }}
          />
          <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
            <CheckIcon
              onClick={() => {
                props.onUpdate(props.index, inputValue)
                setIsEditEnabled(false)
              }}
              sx={{
                padding: '2px',
                cursor: 'pointer',
                border: '1px solid gray',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: '#dddddd'
                }
              }}
            />
            <ClearIcon
              sx={{
                padding: '2px',
                cursor: 'pointer',
                border: '1px solid gray',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: '#dddddd'
                }
              }}
              onClick={() => setIsEditEnabled(false)}
            />
          </div>
        </div>
      ) : (
        <Box
          onMouseEnter={() => setShowActions(true)}
          onMouseLeave={() => setShowActions(false)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer'
          }}
          s
        >
          {props.reason}
          <div style={{ width: '48px', height: '24px', display: 'flex' }}>
            {showActions && (
              <>
                <EditIcon
                  onClick={() => setIsEditEnabled(true)}
                  sx={{
                    padding: '2px',
                    cursor: 'pointer',
                    borderRadius: '4px',
                    '&:hover': {
                      backgroundColor: '#dddddd'
                    }
                  }}
                />
                <DeleteOutlineOutlinedIcon
                  onClick={() => {
                    props.onRemove(props.index)
                  }}
                  sx={{
                    padding: '2px',
                    cursor: 'pointer',
                    borderRadius: '4px',
                    '&:hover': {
                      backgroundColor: '#dddddd'
                    }
                  }}
                />
              </>
            )}
          </div>
        </Box>
      )}
    </div>
  )
}

const AddRejectionReasonModal = (props) => {
  const defaultPartType = {
    label: 'Select part family',
    value: 0
  }
  const [partType, setPartType] = useState(defaultPartType)
  const [partTypeOptions, setPartTypeOptions] = useState([])
  const [
    rejectionReasonForSelectedPartType,
    setRejectionReasonForSelectedPartType
  ] = useState([])
  const [reason, setReason] = useState('')
  const [reasons, setReasons] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchPartTypeOptions = async () => {
      try {
        const response = await operationTypeService.getOperationTypes()
        if (response.success) {
          const options = response.data.map((item) => ({
            value: item.type,
            label: item.type
          }))
          setPartTypeOptions([...options])
        } else {
          showNotification('Failed to fetch part types', 'error')
        }
      } catch (error) {
        console.error('Error fetching part types:', error)
        showNotification('Error fetching part types', 'error')
      }
    }

    fetchPartTypeOptions()
  }, [])

  useEffect(() => {
    if (!partType.value) return

    const fetchRejectionReasonsForPartType = async () => {
      try {
        const response = await rejectionReasonMapService.getRejectionReasons(
          partType.value
        )
        if (response.success) {
          const options = response.data.map((item) => item.reason)
          setRejectionReasonForSelectedPartType(options)
        } else {
          showNotification('Failed to fetch rejection reasons', 'error')
        }
      } catch (error) {
        console.error('Error fetching rejection reasons:', error)
        showNotification('Error fetching rejection reasons', 'error')
      }
    }

    fetchRejectionReasonsForPartType()
  }, [partType])

  const addReason = () => {
    if (!reason) return
    setReasons((prev) => [...prev, { reason: reason.trim() }])
    setReason('')
  }

  const removeReason = (indexToBeRemoved) => {
    setReasons((prev) =>
      prev.filter((_, index) => !(index === indexToBeRemoved))
    )
  }

  const updateReason = (indexToBeUpdated, updatedReason) => {
    setReasons((prev) =>
      prev.map((item, index) => {
        if (index === indexToBeUpdated) {
          return { ...item, reason: updatedReason.trim() }
        }
        return item
      })
    )
  }

  const handleInputKeyUp = (e) => {
    // check if not shift + enter
    if ((e.code === 'Enter' || e.key === 'Enter') && !e.shiftKey) {
      addReason()
    }
  }

  const handleReset = () => {
    setPartType(defaultPartType)
    setRejectionReasonForSelectedPartType([])
    setReason('')
    setReasons([])
  }

  const submitHandler = async () => {
    console.log(reasons, partType.value)
    if (!partType.value) {
      showNotification('Select part family', 'error', 'TOP_CENTER')
      return
    }
    const duplicateReasons = []
    if (rejectionReasonForSelectedPartType.length > 0) {
      reasons.forEach((item) => {
        if (rejectionReasonForSelectedPartType.includes(item.reason)) {
          duplicateReasons.push(item.reason)
        }
      })
    }

    if (duplicateReasons.length > 0) {
      showNotification(
        'Duplicate entries' +
          '\n' +
          `${duplicateReasons.join(', ')} already exist for ${partType.value}`,
        'error',
        'TOP_CENTER',
        false
      )
      return
    }

    setIsLoading(true)
    try {
      const response = await rejectionReasonMapService.addRejectionReasons({
        partType: partType.value,
        reasons: reasons
      })
      console.log(response)
      if (response.success) {
        showNotification('Added rejection reasons', 'success')
        props.onSaveSuccess()
        props.closeHandler()
      } else {
        showNotification('Failed to add rejection reasons', 'error')
      }
    } catch (error) {
      console.error('Error fetching part types:', error)
      showNotification('Failed to add rejection reasons', 'error')
    }
    setIsLoading(false)
  }

  return (
    <StyledModal
      modalWidth={500}
      loaderState={props.isLoading}
      isModalOpen={props.isModalOpen}
      modalTitle={'Add rejection reasons'}
      modalCloseHandler={() => props.closeHandler()}
    >
      <Stack
        spacing={2}
        direction="column"
        sx={{ paddingTop: '16px', width: '100%' }}
      >
        <StyledSelectField
          label=""
          placeHolderText="Select part family"
          selectedValue={partType}
          selectOptions={partTypeOptions}
          onChangeHandler={(selectedOption) => setPartType(selectedOption)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No part family found"
        />
        <Box sx={{ display: 'flex', gap: '8px' }}>
          <StyledTextFieldWithLabel
            label=""
            placeholderText="Type the rejection reason and press enter"
            textValue={reason}
            onChangeHandler={(e) => setReason(e.target.value)}
            isDisabled={false}
            type="text"
            isMargin={false}
            onKeyUpHandler={handleInputKeyUp}
          />
          <Button variant="outlined" onClick={addReason}>
            Add
          </Button>
        </Box>
        <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
          {reasons.length > 0 && (
            <Table style={{ borderCollapse: 'collapse', width: '100%' }}>
              <TableRow>
                <TableHead>S No</TableHead>
                <TableHead>Reason</TableHead>
              </TableRow>

              {reasons.map((item, index) => (
                <TableRow>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <Editable
                      index={index}
                      reason={item.reason}
                      onRemove={removeReason}
                      onUpdate={updateReason}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          )}
        </Box>
      </Stack>
      <Stack
        spacing={2}
        direction="row"
        sx={{
          marginTop: '16px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button variant="outlined" onClick={handleReset}>
          Cancel
        </Button>
        <Button
          {...(isLoading && {
            startIcon: (
              <CircularProgress
                size={14}
                sx={{
                  color: '#fff'
                }}
              />
            )
          })}
          disabled={isLoading || reasons.length === 0}
          variant="contained"
          sx={{
            color: '#fff'
          }}
          onClick={submitHandler}
        >
          Save
        </Button>
      </Stack>
    </StyledModal>
  )
}

export default AddRejectionReasonModal
