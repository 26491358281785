import axios from 'axios'

const BASE_URL = `${process.env.REACT_APP_DATA_LOGGER_BASE_URL}/v1`

const liveDataService = {
  getLiveData: async (params) => {
    try {
      const response = await axios.get(`${BASE_URL}/daily-report/live-data`, {
        params: params
      })
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },

  getProductionRecords: async (params) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/daily-report/production-records`,
        {
          params: params
        }
      )
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  getQualityRecords: async (params) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/daily-report/quality-records`,
        {
          params: params
        }
      )
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  getLiveStatus: async () => {
    try {
      const response = await axios.get(`${BASE_URL}/live-status`)
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  getDailyReportMachineWise: async (params) => {
    console.log(params)
    try {
      const response = await axios.get(
        `${BASE_URL}/daily-report/machine-wise`,
        {
          params: params
        }
      )
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },

  getProductionLossTime: async (params) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/daily-report/production-loss`,
        {
          params: params
        }
      )
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },

  getRejectionLossDailyWise: async (params) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/daily-report/rejection-loss`,
        {
          params: params
        }
      )
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  getRejectionLossMonthWise: async (params) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/daily-report/rejection-loss-monthly`,
        {
          params: params
        }
      )
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },

  getProductionLossMonthWise: async (params) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/daily-report/production-loss-monthly`,
        {
          params: params
        }
      )
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },

  //Historical
  getDashboardData: async (params) => {
    try {
      const response = await axios.get(`${BASE_URL}/historical/monthly-data`, {
        params: params
      })
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  getDemographyData: async (params) => {
    try {
      const response = await axios.get(`${BASE_URL}/historical/demography`, {
        params: params
      })
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  getIdleTimeData: async (params) => {
    try {
      const response = await axios.get(`${BASE_URL}/historical/idle-time`, {
        params: params
      })
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  getPartWiseMonthly: async (params) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/daily-report/part-wise-monthly`,
        {
          params: params
        }
      )
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  }
}

export default liveDataService
