import React, { useState, useEffect } from 'react'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

import StyledTable from '../../components/ReactTable/StyledTable'
import StyledTableContainer from '../../components/StyledTableContainer/StyledTableContainer'
import StyledEmptyHeader from '../../components/StyledEmptyHeader/StyledEmptyHeader'
import rejectionReasonMapService from '../../service/rejectionReasonMap.service'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'
import AddRejectionReasonModal from './AddRejectionReasonModal'

import { showNotification } from '../../utils/toast.utils'
import operationTypeService from '../../service/operationType.service'

const RejectionReasonTable = () => {
  const defaultPartType = {
    label: 'All part family',
    value: 'all'
  }

  const [selectedPartType, setSelectedPartType] = useState(defaultPartType)
  const [partTypeOptions, setPartTypeOptions] = useState([])
  const [reasons, setReasons] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isAddReasonModalOpen, setIsAddReasonModalOpen] = useState(false)
  const [refetchCount, setRefetchCount] = useState(1)

  useEffect(() => {
    const fetchPartTypeOptions = async () => {
      try {
        const response = await operationTypeService.getOperationTypes()
        if (response.success) {
          const options = response.data.map((item) => ({
            value: item.type,
            label: item.type
          }))
          setPartTypeOptions([defaultPartType, ...options])
        } else {
          showNotification('Failed to fetch part types', 'error')
        }
      } catch (error) {
        console.error('Error fetching part types:', error)
        showNotification('Error fetching part types', 'error')
      }
    }

    fetchPartTypeOptions()
  }, [])

  useEffect(() => {
    const fetchReasons = async () => {
      setIsLoading(true)
      try {
        const response = await rejectionReasonMapService.getRejectionReasons()
        setReasons(response.data)
      } catch (error) {
        console.error('Error fetching reasons:', error)
        setReasons([])
      } finally {
        setIsLoading(false)
      }
    }
    fetchReasons()
  }, [refetchCount])

  const onSaveRejectionReasonsSuccess = () => {
    setRefetchCount((prev) => prev + 1)
  }

  const searchObj = {
    ...(selectedPartType.value !== 'all' && {
      partType: selectedPartType.value
    })
  }

  let filteredReasons = reasons
  if (Object.keys(searchObj).length > 0) {
    filteredReasons = reasons.filter((row) => {
      return Object.keys(searchObj).every((key) => searchObj[key] === row[key])
    })
  }

  const reasonsTableColumns = [
    {
      Header: 'Part family',
      accessor: 'partType'
    },
    {
      Header: 'Reason',
      accessor: 'reason'
    }
  ]

  return (
    <>
      <Stack
        sx={{
          position: 'sticky',
          top: 0,
          width: '100%',
          flexDirection: 'column',
          gap: '12px',
          borderBottom: '1px solid #eeeeee',
          backgroundColor: '#fff',
          padding: '12px 16px',
          zIndex: 100
        }}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            gap: '16px'
          }}
        >
          <Button
            sx={{
              textTransform: 'none',
              color: '#fff'
            }}
            variant="contained"
            onClick={() => setIsAddReasonModalOpen(true)}
          >
            Add reasons
          </Button>
          <StyledSelectField
            label=""
            placeHolderText="Select part type"
            selectedValue={selectedPartType}
            selectOptions={partTypeOptions}
            onChangeHandler={(value) => setSelectedPartType(value)}
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No part type found"
            width="50%"
          />
        </Stack>
      </Stack>
      <StyledTableContainer>
        {isLoading && <StyledEmptyHeader>Loading...</StyledEmptyHeader>}
        {!isLoading && filteredReasons.length === 0 && (
          <StyledEmptyHeader>No matching reasons found</StyledEmptyHeader>
        )}
        {!isLoading && filteredReasons.length > 0 && (
          <StyledTable
            pagination={false}
            columns={reasonsTableColumns}
            data={filteredReasons}
            hiddenColumns={['id']}
          />
        )}
      </StyledTableContainer>
      {isAddReasonModalOpen && (
        <AddRejectionReasonModal
          closeHandler={() => setIsAddReasonModalOpen(false)}
          isModalOpen={isAddReasonModalOpen}
          onSaveSuccess={onSaveRejectionReasonsSuccess}
        />
      )}
    </>
  )
}

export default RejectionReasonTable
