import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'

import DashBoardAnalytics from './components/DashBoardAnalytics/DashBoardAnalytics'
import ProductionTrendOverall from './components/ProductionTrend/ProductionTrendOverAll'
import ProductionTrendMachineWise from './components/ProductionTrend/ProductionTrendMachineWise'
import RejectionTrend from './components/RejectionTrend'
import RejectionDoughnut from './components/RejectionDoughnut'
import IdletimeTrend from './components/IdleTimeTrend/IdleTimeTrend'
import IdleTimeDoughnut from './components/IdleTimeDoughnut'
import OeeTrend from './components/OeeTrend/OeeTrend'

import { showNotification } from '../../utils/toast.utils'
import liveDataService from '../../service/liveData.service'
import { useMonthSelectorContext } from '../../context/MonthSelectorContext'

const MyMachineDashboard = () => {
  const { selectedDate } = useMonthSelectorContext()
  const [isLoading, setIsLoading] = useState(false)
  const [dashboardData, setDashboardData] = useState({})
  const [idletimeData, setIdletimeData] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)

        const listOfPromises = [
          liveDataService.getDashboardData({
            currentDate: selectedDate
          }),
          liveDataService.getIdleTimeData({
            currentDate: selectedDate
          })
        ]

        const response = await Promise.allSettled(listOfPromises)

        if (response[0].status === 'fulfilled') {
          setDashboardData(response[0].value.data.data)
        } else {
          showNotification(response.data.message, 'error')
        }

        if (response[1].status === 'fulfilled') {
          setIdletimeData(response[1].value.data.data)
        } else {
          showNotification(response.data.message, 'error')
        }
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [selectedDate])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          padding: '16px'
        }}
      >
        {isLoading && <>Loading...</>}
        {!isLoading && (
          <>
            {dashboardData['overAll'] && (
              <DashBoardAnalytics data={dashboardData['overAll']} />
            )}
            {dashboardData['productionTrend'] && (
              <ProductionTrendOverall
                productionTrend={dashboardData['productionTrend']}
              />
            )}
            {dashboardData['machineWiseProductionInfo'] && (
              <ProductionTrendMachineWise
                machineWiseProductionInfo={
                  dashboardData['machineWiseProductionInfo']
                }
              />
            )}
            {dashboardData['oeeTrend'] && (
              <OeeTrend oeeTrendData={dashboardData['oeeTrend']} />
            )}
            {idletimeData['idleTimeTrend'] && (
              <IdletimeTrend
                idleTimeTrendData={idletimeData['idleTimeTrend']}
              />
            )}
            {idletimeData['idleTimeReasonData'] &&
              idletimeData['idleReasonMap'] && (
                <IdleTimeDoughnut
                  idleTimeData={idletimeData['idleTimeReasonData']}
                  idleReasonMap={idletimeData['idleReasonMap']}
                />
              )}
            {dashboardData['rejectionTrend'] && (
              <RejectionTrend
                rejectionTrendData={dashboardData['rejectionTrend']}
              />
            )}
            {dashboardData['rejectionDataReasonWise'] && (
              <RejectionDoughnut
                rejectionReasonData={dashboardData['rejectionDataReasonWise']}
              />
            )}
          </>
        )}
      </Box>
    </>
  )
}

export default MyMachineDashboard
