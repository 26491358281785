//import { common } from '@mui/material/colors'
import { createTheme } from '@mui/material'
import typography from './typography'

export const lightTheme = createTheme({
  palette: {
    background: {
      default: '#fff',
      paper: '#f5f5f5',
      hover: '#cecece'
    },
    primary: {
      main: '#121213',
      dark: '#000',
      hover: '#368ff9',
      light: '#fff',
      contrast: '#edeff1',
      contrastText: ''
    },
    secondary: {
      main: '#0e4378',
      dark: '#001729',
      light: '#d6d6d6',
      contrastText: ''
    },
    success: {
      main: '#62c370'
    },
    error: {
      main: '#ee2e31'
    },
    warning: {
      main: '#f1d302'
    }
  },
  shape: {
    borderRadius: 10
  },
  spacing: 8,
  typography,
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: { minHeight: 'fit-content', minWidth: '160px' }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: { minHeight: 'fit-content' }
      }
    }
  }
})

export const darkTheme = createTheme({
  palette: {
    background: {
      default: '#1c2128',
      paper: '#303030',
      hover: '#cecece'
    },
    primary: {
      main: '#f5f5f5',
      dark: '#000',
      hover: '#368ff9',
      light: '#768390',
      contrast: '#edeff1',
      contrastText: ''
    },
    secondary: {
      main: '#0e4378',
      dark: '#001729',
      light: '#d6d6d6',
      contrastText: ''
    },
    success: {
      main: '#62c370'
    },
    error: {
      main: '#ee2e31'
    },
    warning: {
      main: '#f1d302'
    }
  },
  shape: {
    borderRadius: 10
  },
  spacing: 8,
  typography,
  components: {
    MuiTab: {
      styleOverrides: {
        root: { minHeight: 'fit-content', minWidth: '160px' }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: { minHeight: 'fit-content' }
      }
    }
  }
})

// boxShadows
//2. box-shadow: 0 2px 12px 0 rgb(18 18 19 / 6%);
//3. box-shadow: 0 8px 28px 0 rgb(18 18 19 / 10%);
//4. box-shadow: 0 11px 44px 0 rgb(18 18 19 / 14%);
//5. box-shadow: 0 24px 65px 0 rgb(18 18 19 / 16%);
//6. box-shadow: 0 32px 72px 0 rgb(20 20 43 / 24%);
