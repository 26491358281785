import axios from 'axios'

const axiosObj = axios.create({
  baseURL: `${process.env.REACT_APP_ADMIN_BASE_URL}/v1`,
  headers: {
    'Content-Type': 'application/json'
  },
  responseType: 'json'
})

const employeeService = {
  getEmployees: async (data) => {
    try {
      const response = await axiosObj.get('/employees', {
        params: {
          ...data
        }
      })

      return response.data
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  addEmployee: async (data) => {
    try {
      const response = await axiosObj.post('/employees', data)
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  editEmployee: async (id, data) => {
    try {
      const response = await axiosObj.patch(`/employees/${id}`, data)
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  deleteEmployee: async (id) => {
    try {
      const response = await axiosObj.delete(`/employees/${id}`)
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  updatePartsInHmi: async () => {
    try {
      const response = await axiosObj.put('/hmi/parts')
      return response.data
    } catch (err) {
      console.log(err)
      return err.response
    }
  }
}

export default employeeService
