import React from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'

import * as DATE_UTILS from '../../utils/date.utils'

const Container = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '8px'
}))

const DateForm = styled('form')(() => ({
  display: 'flex',
  gap: '8px'
}))

const DateInput = styled('input')(() => ({
  outline: 'none',
  border: '1px solid #969696',
  padding: '4px 8px',
  borderRadius: '8px',
  fontSize: '14px',
  fontWeight: 600,
  '&::-webkit-calendar-picker-indicator': {
    padding: '4px',
    marginLeft: '4px',
    borderRadius: '4px',
    cursor: 'pointer'
  }
}))

const DateSelector = ({
  date,
  dateChangeHandler,
  submitHandler,
  prevNextHandler,
  isLoading,
  type = 'date',
  minDate = '2023-12-22',
  maxDate = DATE_UTILS.maxDate(),
  disabled = false
}) => {
  return (
    <Container>
      <DateForm>
        <DateInput
          id="queryDate"
          type={type}
          value={date}
          min={minDate}
          max={maxDate}
          onChange={dateChangeHandler}
          disabled={disabled}
        />
        <Button
          sx={{ borderRadius: '8px' }}
          size="small"
          disabled={disabled || isLoading}
          variant="outlined"
          onClick={submitHandler}
        >
          Submit
        </Button>
        <Button
          sx={{ borderRadius: '8px' }}
          size="small"
          disabled={disabled || isLoading}
          variant="outlined"
          onClick={() => prevNextHandler('prev')}
        >
          Prev
        </Button>
        <Button
          sx={{ borderRadius: '8px' }}
          size="small"
          disabled={disabled || isLoading}
          variant="outlined"
          onClick={() => prevNextHandler('next')}
        >
          Next
        </Button>
      </DateForm>
    </Container>
  )
}

export default DateSelector
