import React, { useState, useEffect } from 'react'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'

import StyledModal from '../../components/StyledModal/StyledModal'
import StyledTextFieldWithLabel from '../../components/StyledTextFieldWithLabel/StyledTextFieldWithLabel'
import { showNotification } from '../../utils/toast.utils'

const Wrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}))

const ShiftResetConfirmation = (props) => {
  const [resetConfirmationText, setResetConfirmationText] = useState('')
  const [isClearChecked, setIsClearChecked] = useState(false)

  return (
    <StyledModal
      modalWidth={500}
      loaderState={props.isLoading}
      isModalOpen={props.isModalOpen}
      modalTitle={props.title}
      modalCloseHandler={() => props.closeHandler()}
    >
      <Stack
        direction="column"
        sx={{ marginBottom: '16px', width: '100%', gap: '16px' }}
      >
        <StyledTextFieldWithLabel
          label={`To confirm this action type "${props.confirmText}" in the field.`}
          placeholderText={props.confirmText}
          textValue={resetConfirmationText}
          onChangeHandler={(e) => setResetConfirmationText(e.target.value)}
          isDisabled={false}
          type="text"
          isMargin={true}
        />
        <Wrapper>
          <Typography sx={{ color: '#000' }} variant="base2">
            Clear shift data?
          </Typography>
          <Checkbox
            sx={{ padding: 0 }}
            checked={isClearChecked}
            onChange={(e) => setIsClearChecked(e.target.checked)}
          />
        </Wrapper>
      </Stack>
      <Stack
        spacing={2}
        direction="row"
        sx={{
          marginTop: '16px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button variant="outlined" onClick={props.closeHandler}>
          Cancel
        </Button>
        <Button
          disabled={resetConfirmationText !== props.confirmText}
          variant="contained"
          sx={{
            color: '#fff',
            '&:disabled': {
              cursor: 'not-allowed'
            }
          }}
          onClick={() => {
            props.confirmationHandler(isClearChecked)
          }}
        >
          Confirm
        </Button>
      </Stack>
    </StyledModal>
  )
}

export default ShiftResetConfirmation
