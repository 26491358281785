import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

//components
import StyledPageContainer from '../../components/StyledPageContainer/StyledPageContainer'
import CreateShiftConfig from './components/CreateShiftConfig'
import ConfigList from './components/ConfigList'

const liveDataTabs = {
  CONFIGURATION_LIST: { name: 'Configuration list', value: 'config-list' },
  CREATE_CONFIGURATION: { name: 'Create configuration', value: 'create-config' }
}

const TabsContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '8px 24px 0 0',
  borderBottom: `1px solid #eeeeee`
}))

const Tab = styled('div')(({ theme, isActive }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '16px',
  minWidth: 'fit-content',
  padding: '8px 20px',
  color: isActive ? theme.palette.primary.main : '#ccccd7',
  cursor: 'pointer',
  borderBottom: isActive
    ? `1px solid ${theme.palette.primary.main}`
    : '1px solid transparent',
  '&:hover': {
    color: `${theme.palette.primary.main}`
  }
}))

const CreateConfig = () => {
  let defaultTab = liveDataTabs.CONFIGURATION_LIST.value

  //hooks
  const navigate = useNavigate()
  const location = useLocation()

  //extract category from URL
  const params = new URLSearchParams(location.search)
  const tab = params.get('tab')

  //If category present in the URL override the defaultJobCategory
  if (tab) {
    defaultTab = tab
  }

  //component state
  const [activeSubTab, setActiveSubTab] = useState(defaultTab)

  useEffect(() => {
    navigate(`?tab=${activeSubTab}`)
  }, [activeSubTab])

  const handleSubTabChange = (tab) => {
    setActiveSubTab(tab)
  }

  return (
    <StyledPageContainer>
      <TabsContainer>
        {React.Children.toArray(
          Object.values(liveDataTabs).map((tab, i) => (
            <Tab
              isActive={activeSubTab === tab.value}
              onClick={() => handleSubTabChange(tab.value)}
            >
              {tab.name}
            </Tab>
          ))
        )}
      </TabsContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 49.6px)',
          overflowY: 'auto',
          padding:'16px'
        }}
      >
        {activeSubTab === liveDataTabs.CONFIGURATION_LIST.value && (
          <ConfigList />
        )}
        {activeSubTab === liveDataTabs.CREATE_CONFIGURATION.value && (
          <CreateShiftConfig />
        )}
      </Box>
    </StyledPageContainer>
  )
}

export default CreateConfig
