import React, { useState } from 'react'
import styled from '@emotion/styled/macro'

const IdleCard = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 16px;
`

const getColor = (reason) => {
  const map = {
    ST0: 'lightgreen',
    ST1: '#541388',
    ST2: '#d90368',
    ST3: '#f1e9da',
    ST4: '#ffd400',
    ST5: '#dfff00',
    ST6: '#c49991',
    ST7: '#B9D6F2',
    ST8: '#061A40',
    ST9: '#0353A4',
    ST10: '#0353A4',
    ST11: '#40e0d0',
    ST12: '#ccccff'
  }

  return map[reason] || 'red'
}

const getIdleReason = (reason) => {
  const map = {
    ST0: 'Running',
    ST1: 'Coil Bend',
    ST2: 'Burr',
    ST3: 'Damage',
    ST4: 'Coil Change',
    ST5: 'To Much Sizing',
    ST6: 'Part Setting',
    ST7: 'Cam setting',
    ST8: 'Power Failure',
    ST9: 'Under Maintenance',
    ST10: 'Tool Broken',
    ST11: 'No Operator',
    ST12: 'Others'
  }

  return map[reason] || 'Not selected'
}

const LiveDataBar = ({ liveData, width, count }) => {
  const [isData, setIsData] = useState(false)

  let totalIdleTime = 0

  for (let i = 0; i < liveData.length; i++) {
    totalIdleTime += liveData[i].idleTime
  }

  return (
    <div style={{ position: 'relative' }}>
      <IdleCard>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            padding: '8px 16px',
            backgroundColor: '#fff',
            borderRadius: '8px',
            boxShadow: ' 0 8px 28px 0 rgb(18 18 19 / 10%)'
          }}
        >
          <p>Total Idletime:</p>
          <b>{totalIdleTime} minutes</b>
        </div>
      </IdleCard>
      <div
        style={{
          display: 'flex',
          maxWidth: `${width}px`,
          marginBottom: '16px'
        }}
      >
        {React.Children.toArray(
          liveData.map((data) => (
            <div
              onMouseEnter={() => {
                setIsData(data)
              }}
              onMouseLeave={() => {
                setIsData({})
              }}
              style={{
                position: 'relative',
                width: `${width / count}px`,
                height: '100px',
                backgroundColor: getColor(data.idleReason),
                cursor: 'pointer'
              }}
            ></div>
          ))
        )}
      </div>
      <div>
        <div>Time: {isData.time || 'Hover on the chart'}</div>
        <div>
          Reason:{' '}
          {isData ? getIdleReason(isData.idleReason) : 'Hover on the chart'}
        </div>
        <div>
          Machine status:{' '}
          {isData
            ? isData.machineStatus === 0
              ? 'Running'
              : 'Idle'
            : 'Hover on the chart'}
        </div>
        <div> Part family: {isData.partName || 'Hover on the chart'}</div>
        <div> Part number: {isData.partType || 'Hover on the chart'}</div>
        <div> Operator: {isData.operator || 'Hover on the chart'}</div>
        <div> Supervisor: {isData.supervisor || 'Hover on the chart'}</div>
        <div>
          Line Inspector: {isData.lineInspector || 'Hover on the chart'}
        </div>
      </div>
    </div>
  )
}

export default LiveDataBar
