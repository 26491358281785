import React from 'react'
import { Backdrop, CircularProgress, styled, useTheme } from '@mui/material'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'

const ModalBody = styled('div')<{
  width: number
  height: number | undefined
  bgColor: React.CSSProperties['color']
  gap: number
}>(({ theme, width, height, bgColor, gap }) => ({
  position: 'relative',
  width: `${width}px`,
  height: height ? `${height}px` : undefined,
  display: 'flex',
  flexDirection: 'column',
  padding: '32px',
  gap: `${gap}px`,
  borderRadius: '24px',
  backgroundColor: bgColor ? bgColor : '#fff'
}))

export const TitleWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column'
}))

const Title = styled(Typography)(({ theme }) => ({
  color: '#000'
}))

const SubTitle = styled(Typography)(({ theme }) => ({
  color: '#000'
}))

export const CloseIconWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: '24px',
  top: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  padding: '8px',
  background: '#fff',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: '#cecece'
  }
}))

const IconContainer = styled('div')<{
  src: string
  width?: number
  height?: number
}>(({ src, width, height }) => ({
  display: 'flex',
  backgroundImage: `url(${src})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  width: width ? `${width}px` : '24px',
  height: height ? `${height}px` : '24px',
  borderRadius: '12px'
}))

type Props = {
  isOpen: boolean
  title?: string
  subTitle?: string | JSX.Element
  closeHandler: () => void
  children?: React.ReactNode
  loading?: boolean
  width: number
  height?: number
  bgColor?: React.CSSProperties['color']
  gap?: number
}

const StyledModal = (props: Props) => {
  const {
    isOpen = false,
    title,
    subTitle,
    closeHandler,
    children,
    width,
    height,
    bgColor,
    loading = false,
    gap = 16
  } = props

  const theme = useTheme()

  return (
    <Modal
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: '40px',
        backgroundColor: 'rgba(20, 23, 24, 0.95)',
        overflowY: 'auto'
      }}
      disableEnforceFocus
      disableAutoFocus
      open={isOpen}
    >
      <ModalBody width={width} height={height} bgColor={bgColor} gap={gap}>
        <CloseIconWrapper onClick={() => closeHandler()}>
          <CloseIcon />
        </CloseIconWrapper>
        {title && (
          <TitleWrapper>
            <Title variant="h4">{title}</Title>
            {subTitle && <SubTitle>{subTitle}</SubTitle>}
          </TitleWrapper>
        )}
        {children}
        <Backdrop sx={{ zIndex: 99 }} open={loading}>
          <CircularProgress />
        </Backdrop>
      </ModalBody>
    </Modal>
  )
}

export default StyledModal
