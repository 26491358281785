import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'

//components
import StyledPageContainer from '../../components/StyledPageContainer/StyledPageContainer'
import PartManagement from './Part'
import EmployeeManagement from './Employee'
import OperationType from './OperationType'
import Machine from './Machine'
import RejectionReasonTable from './RejectionReasonTable'
import NewProductionOrder from './NewProductionOrder'
import PartToolsInfo from './PartToolsInfo'

const manageDataTabs = {
  PART: { name: 'Part', value: 'part' },
  OPERATION: { name: 'Part family', value: 'part-family' },
  EMPLOYEE: { name: 'Employee', value: 'employee' },
  MACHINE: { name: 'Machine', value: 'machine' },
  PRODUCTION_ORDER_NEW: {
    name: 'Production order new',
    value: 'production-order-new'
  },
  REJECTION_LIST: { name: 'Rejection reason', value: 'rejection-reason' }
  //PART_TOOLS: { name: 'Part tools info', value: 'part-tools' },
}

const TabsContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '8px 24px 0 0',
  borderBottom: `1px solid #eeeeee`
}))

const Tab = styled('div')<{ isActive: boolean }>(({ theme, isActive }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '16px',
  minWidth: 'fit-content',
  padding: '8px 20px',
  color: isActive ? theme.palette.primary.main : '#ccccd7',
  cursor: 'pointer',
  borderBottom: isActive
    ? `1px solid ${theme.palette.primary.main}`
    : '1px solid transparent',
  '&:hover': {
    color: `${theme.palette.primary.main}`
  }
}))

const ManageData = () => {
  //default values
  let defaultTab = manageDataTabs.PART.value

  //hooks
  const navigate = useNavigate()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const tab = params.get('tab')

  if (tab) {
    defaultTab = tab
  }

  //local
  const [activeSubTab, setActiveSubTab] = useState(defaultTab)

  useEffect(() => {
    navigate(`?tab=${activeSubTab}`)
  }, [activeSubTab])

  const handleSubTabChange = (tab: string) => {
    setActiveSubTab(tab)
  }

  return (
    <StyledPageContainer>
      <TabsContainer>
        {React.Children.toArray(
          Object.values(manageDataTabs).map((tab) => (
            <Tab
              isActive={activeSubTab === tab.value}
              onClick={() => handleSubTabChange(tab.value)}
            >
              {tab.name}
            </Tab>
          ))
        )}
      </TabsContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 49.6px)',
          overflowY: 'auto'
        }}
      >
        {activeSubTab === manageDataTabs.PART.value && <PartManagement />}
        {activeSubTab === manageDataTabs.OPERATION.value && <OperationType />}
        {activeSubTab === manageDataTabs.EMPLOYEE.value && (
          <EmployeeManagement />
        )}
        {activeSubTab === manageDataTabs.MACHINE.value && <Machine />}

        {activeSubTab === manageDataTabs.PRODUCTION_ORDER_NEW.value && (
          <NewProductionOrder />
        )}
        {activeSubTab === manageDataTabs.REJECTION_LIST.value && (
          <RejectionReasonTable />
        )}
        {/* {activeSubTab === manageDataTabs.PART_TOOLS.value && <PartToolsInfo />} */}
      </Box>
    </StyledPageContainer>
  )
}

export default ManageData
