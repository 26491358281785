import React from 'react'

const MonthSelectorContext = React.createContext()

export function useMonthSelectorContext() {
  const monthSelectorData = React.useContext(MonthSelectorContext)

  if (monthSelectorData === undefined) {
    throw new Error(
      'useMonthSelectorContext must be used with in MonthSelectorContextProvider'
    )
  }
  return monthSelectorData
}

export default MonthSelectorContext
