import React from 'react'
import StyledModal from '../../components/StyledModal/Modal'
import { Box, Typography } from '@mui/material'
import { IPartApi } from '../../types/parts'

interface Props {
  isModalOpen: boolean
  closeHandler: () => void
  defaultData: IPartApi
}

const PartToolPreviewModal = (props: Props) => {
  return (
    <StyledModal
      width={500}
      isOpen={props.isModalOpen}
      title="Tools info"
      closeHandler={() => props.closeHandler()}
    >
      <Typography> Part number: {props.defaultData.type}</Typography>
      {props.defaultData.toolMaps.length === 0 && (
        <Typography> No tools information available</Typography>
      )}
      {props.defaultData.toolMaps
        .sort((a, b) => a.toolNumber - b.toolNumber)
        .map((tool) => (
          <Box
            sx={{
              border: '1px solid gray',
              padding: '8px 16px',
              borderRadius: '4px'
            }}
          >
            <Typography>
              Tool {tool.toolNumber}: ({tool.toolType.type})
            </Typography>
          </Box>
        ))}
    </StyledModal>
  )
}

export default PartToolPreviewModal
