import axios from 'axios'

import {
  ITool,
  ToolFilter,
  ToolsApiData,
  ToolsUsageApiData,
  TypeAddToolData
} from '../types/tools'
import { ApiErrorResponse, ApiSuccessResponse } from '../types/common'

const axiosObj = axios.create({
  baseURL: `${process.env.REACT_APP_ADMIN_BASE_URL}/v1`,
  headers: {
    'Content-Type': 'application/json'
  },
  responseType: 'json'
})

const handleApiError = (error: unknown): ApiErrorResponse => {
  if (axios.isAxiosError(error)) {
    // Handle specific HTTP status codes if needed

    // NOTE: This syntax has some parsing problem
    // if (error.response?.status === 404) {
    //   console.log('Not Found Error:', error.message)
    // }

    if (error.response && error.response.status === 400) {
      console.log('Bad request:', error.message)
    }

    if (error.response && error.response.status === 401) {
      console.log('Auth error:', error.message)
    }

    if (error.response && error.response.status === 404) {
      console.log('Not found error:', error.message)
    }

    return {
      success: false,
      message:
        error.response && error.response.data
          ? error.response.data.message
          : 'Internal server error'
    }
  } else {
    // Handle other errors (e.g., network errors)
    return { success: false, message: 'Internal server error' }
  }
}

const toolService = {
  getTools: async (
    filter: ToolFilter
  ): Promise<ApiSuccessResponse<ToolsApiData[]> | ApiErrorResponse> => {
    try {
      const response = await axiosObj.get('/tool', {
        params: {
          ...filter
        }
      })

      return response.data
    } catch (error) {
      return handleApiError(error)
    }
  },
  getToolsUsage: async (): Promise<
    ApiSuccessResponse<ToolsUsageApiData[]> | ApiErrorResponse
  > => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DATA_LOGGER_BASE_URL}/v1/tool-life-monitoring`
      )

      return response.data
    } catch (error) {
      return handleApiError(error)
    }
  },
  addTool: async (
    data: TypeAddToolData
  ): Promise<ApiSuccessResponse<ITool> | ApiErrorResponse> => {
    try {
      const response = await axiosObj.post('/tool', data)
      return response.data
    } catch (error) {
      return handleApiError(error)
    }
  },
  editTool: async (
    id: string,
    data: Partial<ITool>
  ): Promise<ApiSuccessResponse<ITool> | ApiErrorResponse> => {
    try {
      const response = await axiosObj.patch(`/tool/${id}`, data)
      return response.data
    } catch (error) {
      return handleApiError(error)
    }
  },
  deleteTool: async (id: string) => {
    try {
      const response = await axiosObj.delete(`/tool/${id}`)
      return response.data
    } catch (error) {
      console.log(handleApiError)
      return handleApiError(error)
    }
  }
}

export default toolService
