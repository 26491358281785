import React, { useState } from 'react'
import { styled } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'

const SidebarLink = styled('div')(({ theme, isActive }) => ({
  userSelect: 'none',
  color: 'black',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '14px',
  padding: '8px',
  borderRadius: '4px',
  backgroundColor: isActive ? theme.palette.primary.contrast : '#fff',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.primary.contrast,
    color: theme.palette.primary.main
  }
}))

const NavTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap:'8px'
}))

const SidebarLabel = styled('span')(({ theme }) => ({}))

const DropdownLink = styled('div')(({ theme, isActive }) => ({
  height: '40px',
  paddingLeft: '24px',
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  color: 'black',
  fontSize: '14px',
  cursor: 'pointer',
  borderRadius: '4px',
  backgroundColor: isActive ? theme.palette.primary.contrast : '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.contrast,
    color: theme.palette.primary.main
  }
}))

const MenuItem = ({ item }) => {
  // hooks
  const navigate = useNavigate()
  const location = useLocation()

  // component state
  const [subNav, setSubNav] = useState(false)
  const showSubNav = () => setSubNav(!subNav)

  return (
    <>
      <SidebarLink
        isActive={window.location.pathname === item.path}
        //isActive={item.path === location.pathname && !item.subNav}
        onClick={() => {
          if (item.subNav) {
            showSubNav()
          }
          if (!item.subNav) {
            navigate(item.path)
          }
        }}
      >
        <NavTitle>
          {item.renderIcon()}
          <SidebarLabel>{item.title}</SidebarLabel>
        </NavTitle>
        <div>
          {item.subNav && subNav
            ? item.iconOpened()
            : item.subNav
            ? item.iconClosed()
            : null}
        </div>
      </SidebarLink>
      {subNav &&
        item.subNav.map((subItem, index) => {
          return (
            <DropdownLink
              isActive={location.pathname === subItem.path}
              onClick={() => navigate(subItem.path)}
              key={index}
            >
              {subItem.renderIcon()}
              <SidebarLabel>{subItem.title}</SidebarLabel>
            </DropdownLink>
          )
        })}
    </>
  )
}

export default MenuItem
