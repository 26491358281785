import React from 'react'
import { Box, Container, Typography } from '@mui/material'

const NotFound = () => (
  <>
    {/* <title>404 | Distinct Productivity solutions</title> */}

    <Box
      style={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center'
      }}
    >
      <Container maxWidth="md">
        <Typography align="center" color="textPrimary" variant="h1">
          404: The page you are looking for isn’t here
        </Typography>
        <Typography align="center" color="textPrimary" variant="subtitle2">
          You either tried some shady route or you came here by mistake.
          Whichever it is, try using the navigation
        </Typography>
        <Box sx={{ textAlign: 'center' }}></Box>
      </Container>
    </Box>
  </>
)

export default NotFound
