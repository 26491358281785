import React from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import RadialGauge from '../RadialGauge/RadialGauge'

const Container = styled('div')(() => ({
  position: 'relative',
  width: '400px',
  display: 'flex',
  gap: '16px',
  padding: '12px',
  borderRadius: '8px',
  border: '1px solid lightgray'
  //cursor: 'pointer'
}))

const InfoContainer = styled('div')(() => ({
  flex: 2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
  //gap: '2px',
  //padding: '10px 0'
}))

const Dot = styled('div')(() => ({
  height: '8px',
  width: '8px',
  borderRadius: '50%',
  backgroundColor: '#000'
}))

const CartTitle = styled('h4')(() => ({
  position: 'absolute',
  top: '8px',
  left: '16px',
  width: 'fit-content',
  borderBottom: '1px solid gray'
}))

const KPITable = styled('table')(() => ({
  borderCollapse: 'collapse',
  width: '100%'
}))

const KPITableRow = styled('tr')(() => ({}))

const KPITableEntry = styled('td')(() => ({
  textAlign: 'left',
  padding: '2px'
}))

const DemographyCard = (props) => {
  const navigate = useNavigate()
  const { machine, OEE, partCount, target, rejectionCount, idletime } = props
  return (
    <Container
    // onClick={() => {
    //   props.tabChange(`machine=${machine}`)
    // }}
    >
      <CartTitle>{machine}</CartTitle>
      <InfoContainer>
        <KPITable>
          {[
            { label: 'Achieved', value: partCount },
            { label: 'Target', value: target },
            { label: 'Rejection', value: rejectionCount },
            { label: 'Idletime', value: idletime }
          ].map((item) => {
            return (
              <KPITableRow>
                <KPITableEntry>{item.label}</KPITableEntry>
                <KPITableEntry>{item.value.toLocaleString()}</KPITableEntry>
              </KPITableRow>
            )
          })}
        </KPITable>
      </InfoContainer>
      <Box
        sx={{
          flex: 1,
          display: 'flex'
        }}
      >
        <RadialGauge value={OEE} />
      </Box>
    </Container>
  )
}

export default DemographyCard
