export const BAR = {
  barThickness: 11,
  borderRadius: 2,
  borderWidth: 2
}

export const TOOLTIP = {
  mode: 'index',
  xAlign: 'center',
  yAlign: 'bottom',
  titleAlign: 'center',
  bodyAlign: 'center',
  backgroundColor: '#282c34',
  bodyColor: '#fff'
}

export const LEGEND = {
  display: true,
  position: 'bottom',
  labels: {
    boxWidth: 15,
    font: {
      size: 14
    }
  }
}
export const X_SCALE = {
  title: {
    // text:' Days',
    color: 'black',

    font: {
      size: 14,
      weight: 'bold',
      family: 'Arial'
    },
    display: true
  },
  grid: {
    color: 'rgb(255, 255, 255)',
    borderColor: 'grey',
    tickColor: 'black',
    offset: false,
    tickBorderDashOffset: 5
  },
  ticks: {
    padding: 5
  }
}
export const Y_SCALE = {
  title: {
    text: ' OEE Trend ',
    color: 'black',
    font: {
      size: 14,
      weight: 'bold',
      family: 'Arial'
    },
    display: true
  },
  grid: {
    color: 'rgb(200, 200, 200)',
    borderColor: 'grey',
    tickColor: 'black',
    borderDash: [2],
    offset: false
  },
  ticks: {
    min: 5,
    max: 100,
    stepSize: 10
  }
}
