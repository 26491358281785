import { OP_REASONS } from './reasons.utils'
//import * as html2canvas from 'html2canvas'

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getOpType = (data, timeFrame) => {
  const types = Object.keys(data[timeFrame])
    .filter((el) => el.includes('OP') && el !== 'OP115')
    .sort((a, b) => a.slice(-3) - b.slice(-3))
  return types
}

export const rejectionDataMapper = (data, timeFrame, opType, shift) => {
  // if (!opType || !data[timeFrame]) return
  // TODO: Fix this error in below line
  // ERROR: Module parse failed: Unexpected token (19:40)
  // const dataObj = data[timeFrame][opType]?.[shift]
  // if (!dataObj) return
  // delete dataObj['total_rejection']
  // const chartData = []
  // for (const property in dataObj) {
  //   chartData.push({
  //     oldName: property,
  //     newName: OP_REASONS[opType][property],
  //     value: dataObj[property]
  //   })
  // }
  // chartData.sort((a, b) => b.value - a.value)
  // const labels = chartData.map((el) => el['newName'])
  // const values = chartData.map((el) => el['value'])
  // return {
  //   labels,
  //   values
  // }
}

export const idletimeDataMapper = (data, timeFrame, shift) => {
  const dataObj = data[timeFrame][shift]
  delete dataObj['total_idletime']

  const chartData = []
  for (const property in dataObj) {
    chartData.push({
      oldName: property,
      newName: capitalizeFirstLetter(property.split('_').join(' ')),
      value: dataObj[property]
    })
  }
  chartData.sort((a, b) => b.value - a.value)
  const labels = chartData.map((el) => el['newName'])
  const values = chartData.map((el) => el['value'])

  return {
    labels,
    values
  }
}

// export const takeASnap = (id) => {
//   let screenshotDiv = document.querySelector(id)
//   html2canvas(screenshotDiv).then((canvas) => {
//     var a = document.createElement('a')
//     a.href = canvas
//       .toDataURL('image/png')
//       .replace('image/jpeg', 'image/octet-stream')
//     a.download = 'somefilename.jpg'
//     a.click()
//   })
// }

export const getDisplayName = (value, list) => {
  //console.log(value, list)
  const requiredObject = list.find((item) => item.value === value)

  return requiredObject ? requiredObject.label : value
}
