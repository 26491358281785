import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import InputHeader from './components/Input/InputHeader'
import DashBoardAnalytics from './components/DashBoardAnalytics/DashBoardAnalytics'
import ProductionTrendOverall from './components/ProductionTrend/ProductionTrendOverAll'
import OeeTrend from './components/OeeTrend/OeeTrend'
import RejectionTrend from './components/RejectionTrend'
import IdletimeTrend from './components/IdleTimeTrend/IdleTimeTrend'
import IdleTimeDoughnut from './components/IdleTimeDoughnut'
import RejectionDoughnut from './components/RejectionDoughnut'

import { showNotification } from '../../utils/toast.utils'
import liveDataService from '../../service/liveData.service'
import * as DATE_UTILS from '../../utils/date.utils'
import { MACHINES } from '../../utils/constant.utils'
import { useMonthSelectorContext } from '../../context/MonthSelectorContext'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'

const machineOptions = [...MACHINES]

const MachineWiseHistorical = () => {
  const location = useLocation()
  const { selectedDate } = useMonthSelectorContext()

  //extract category from URL
  const params = new URLSearchParams(location.search)
  const machine = params.get('machine')

  const defaultMachine = machineOptions.find(({ value }) => value === machine)

  const [isLoading, setIsLoading] = useState(false)
  const [dashboardData, setDashboardData] = useState({})
  const [idletimeData, setIdletimeData] = useState({})

  const [selectedMachine, setSelectedMachine] = useState(
    defaultMachine || machineOptions[0]
  )

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)

        const listOfPromises = [
          liveDataService.getDashboardData({
            currentDate: selectedDate,
            machine: selectedMachine.value
          }),
          liveDataService.getIdleTimeData({
            currentDate: selectedDate,
            machine: selectedMachine.value
          })
        ]

        const response = await Promise.allSettled(listOfPromises)

        if (response[0].status === 'fulfilled') {
          setDashboardData(response[0].value.data.data)
        } else {
          showNotification(response.data.message, 'error')
        }

        if (response[1].status === 'fulfilled') {
          setIdletimeData(response[1].value.data.data)
        } else {
          showNotification(response.data.message, 'error')
        }
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [selectedDate, selectedMachine])

  return (
    <>
      <Stack
        sx={{
          position: 'sticky',
          top: 0,
          width: '100%',
          flexDirection: 'column',
          gap: '12px',
          borderBottom: '1px solid #eeeeee',
          backgroundColor: '#fff',
          padding: '12px 16px',
          zIndex: 100
        }}
      >
        <Box
          sx={{
            display: 'flex'
            // margin: 'auto',
            // width: '300px'
          }}
        >
          <StyledSelectField
            label=""
            placeHolderText="Select machine"
            selectedValue={selectedMachine}
            selectOptions={machineOptions}
            onChangeHandler={(value) => setSelectedMachine(value)}
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No machine found"
          />
        </Box>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          padding: '16px'
        }}
      >
        {isLoading && <>Loading...</>}
        {!isLoading && (
          <>
            {dashboardData['overAll'] && (
              <DashBoardAnalytics
                isMachineWise={true}
                data={dashboardData['overAll']}
              />
            )}
            {dashboardData['productionTrend'] && (
              <ProductionTrendOverall
                productionTrend={dashboardData['productionTrend']}
              />
            )}
            {dashboardData['oeeTrend'] && (
              <OeeTrend oeeTrendData={dashboardData['oeeTrend']} />
            )}
            {idletimeData['idleTimeTrend'] && (
              <IdletimeTrend
                idleTimeTrendData={idletimeData['idleTimeTrend']}
              />
            )}
            {idletimeData['idleTimeReasonData'] &&
              idletimeData['idleReasonMap'] && (
                <IdleTimeDoughnut
                  idleTimeData={idletimeData['idleTimeReasonData']}
                  idleReasonMap={idletimeData['idleReasonMap']}
                />
              )}
            {dashboardData['rejectionTrend'] && (
              <RejectionTrend
                rejectionTrendData={dashboardData['rejectionTrend']}
              />
            )}
            {dashboardData['rejectionDataReasonWise'] && (
              <RejectionDoughnut
                rejectionReasonData={dashboardData['rejectionDataReasonWise']}
              />
            )}
          </>
        )}
      </Box>
    </>
  )
}

export default MachineWiseHistorical
