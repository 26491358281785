import React, { useState } from 'react'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

import StyledModal from '../../components/StyledModal/StyledModal'
import StyledTextFieldWithLabel from '../../components/StyledTextFieldWithLabel/StyledTextFieldWithLabel'

const AddMachine = (props) => {
  let defaultName = ''
  let defaultDisplayName = ''

  if (props.isEditMode) {
    defaultName = props.selectedPart.name
    defaultDisplayName = props.selectedPart.displayName
  }

  const [name, setName] = useState(defaultName)
  const [displayName, setDisplayName] = useState(defaultDisplayName)

  return (
    <StyledModal
      modalWidth={500}
      loaderState={props.isLoading}
      isModalOpen={props.isModalOpen}
      modalTitle={props.isEditMode ? 'Edit machine info' : 'Add machine'}
      modalCloseHandler={() => props.closeHandler()}
    >
      <Stack
        spacing={2}
        direction="column"
        sx={{ marginBottom: '16px', width: '100%' }}
      >
        <StyledTextFieldWithLabel
          label="Machine name"
          placeholderText="Enter machine"
          textValue={name}
          onChangeHandler={(e) => setName(e.target.value)}
          isDisabled={false}
          type="text"
          isMargin={true}
        />
        <StyledTextFieldWithLabel
          label="Display name"
          placeholderText="Enter display name"
          textValue={displayName}
          onChangeHandler={(e) => setDisplayName(e.target.value)}
          isDisabled={false}
          type="text"
          isMargin={true}
        />
      </Stack>
      <Stack
        spacing={2}
        direction="row"
        sx={{
          marginTop: '16px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button variant="outlined" onClick={props.closeHandler}>
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            color: '#fff'
          }}
          onClick={() =>
            props.isEditMode
              ? props.editPart(props.selectedPart.id, {
                  name,
                  displayName
                })
              : props.addPart({
                  name,
                  displayName
                })
          }
        >
          {props.isEditMode ? 'Update' : 'Add'}
        </Button>
      </Stack>
    </StyledModal>
  )
}

export default AddMachine
