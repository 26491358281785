import React, { useEffect } from 'react'
import { useTable, usePagination } from 'react-table'

import Pagination from './Pagination'
import { StyledTableRoot, StyledTd, StyledTh, TrBodyRow } from './styles'

const StyledTable = (props) => {
  const columns = React.useMemo(() => props.columns, [props.columns])
  const data = React.useMemo(() => props.data, [props.data])

  const tableInstance = useTable(
    {
      columns: columns,
      data: data,
      initialState: {
        hiddenColumns: props.hiddenColumns || []
      }
    },
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,which has only the rows for the active pageThe rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex }
  } = tableInstance

  useEffect(() => {
    if (props.pagination) {
      setPageSize(props.pageCount)
    }
    setPageSize(data.length)
  }, [data.length, props.pageCount, props.pagination, setPageSize])

  return (
    <>
      <StyledTableRoot {...getTableProps()}>
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <StyledTh {...column.getHeaderProps()}>
                      {
                        // Render the header
                        column.render('Header')
                      }
                    </StyledTh>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            page.map((row) => {
              // Prepare the row for display
              prepareRow(row)

              return (
                // Apply the row props
                <TrBodyRow
                  onClick={() =>
                    props.rowClickHandler
                      ? props.rowClickHandler(row.values)
                      : console.log('DEFAULT')
                  }
                  key={row.values.name}
                  {...row.getRowProps()}
                >
                  {
                    // Loop over the rows cells
                    row.cells.map((cell, index) => {
                      // Apply the cell props
                      return (
                        <StyledTd {...cell.getCellProps()}>
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </StyledTd>
                      )
                    })
                  }
                </TrBodyRow>
              )
            })
          }
        </tbody>
      </StyledTableRoot>
      {props.pagination && (
        <Pagination
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
        />
      )}
    </>
  )
}

export default StyledTable
