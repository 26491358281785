import React from 'react'
import { styled } from '@mui/material'
import { Bar } from 'react-chartjs-2'
import * as CHART_UTILS from './BarChart.constant'

const Container = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}))

const BarChart = (props) => {
  const { chartLabels, dataGroup, yLabel } = props

  const barChart = dataGroup ? (
    <Bar
      data={{
        labels: chartLabels,
        datasets: dataGroup
      }}
      height={300}
      width={900}
      options={{
        plugins: {
          title: { display: false },
          tooltip: CHART_UTILS.TOOLTIP,
          legend: CHART_UTILS.LEGEND
        },

        scales: {
          x: CHART_UTILS.X_SCALE,
          y: {
            ...CHART_UTILS.Y_SCALE,
            title: { ...CHART_UTILS.Y_SCALE.title, text: yLabel }
          }
        }
      }}
    />
  ) : null

  return <Container>{barChart}</Container>
}

export default BarChart
