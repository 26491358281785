import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import Box from '@mui/material/Box'
//import AddIcon from '@mui/icons-material/Add'

import StyledTable from '../../components/ReactTable/StyledTable'
import StyledPageContainer from '../../components/StyledPageContainer/StyledPageContainer'
import StyledTableContainer from '../../components/StyledTableContainer/StyledTableContainer'
import StyledEmptyHeader from '../../components/StyledEmptyHeader/StyledEmptyHeader'
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal'
import EmployeeUploadModal from './AddEmployeeModal'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'

//assets
import { DeleteIcon, EditIcon } from '../../icons/Icons'

//services
import employeeService from '../../service/employee.service'

//Utils
import { showNotification } from '../../utils/toast.utils'
import { ROLES } from '../../utils/constant.utils'
import { getDisplayName } from '../../utils/common.utils'

const EmployeeManagement = () => {
  const defaultDesignation = {
    value: 'all',
    label: 'All designations'
  }
  const defaultEmployeeId = {
    value: 'all',
    label: 'All ids'
  }
  const defaultEmployeeName = {
    value: 'all',
    label: 'All names'
  }

  const [employees, setEmployees] = useState([])
  const [employeeIdOptions, setEmployeeIdOptions] = useState([])
  const [employeeNameOptions, setEmployeeNameOptions] = useState([])

  const [employeeId, setEmployeeId] = useState(defaultEmployeeId)
  const [employeeName, setEmployeeName] = useState(defaultEmployeeName)
  const [employeeRole, setEmployeeRole] = useState(defaultDesignation)

  const [isLoading, setIsLoading] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [openAddEmployeeModal, setOpenAddEmployeeModal] = useState(false)
  const [selectedEmployee, setSelectedEmployee] = useState({})
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const fetchEmployees = async () => {
    const data = {}

    // if (employeeId.value !== 0) {
    //   data.employeeId = employeeId
    // }

    // if (employeeName.value !== 0) {
    //   data.employeeName = employeeName
    // }

    // if (employeeRole.value !== 0) {
    //   data.employeeRole = employeeRole.value
    // }

    try {
      setIsLoading(true)
      const employeesResponse = await employeeService.getEmployees(data)
      const employeesData = []
      const employeeIdOptionsObj = {
        all: defaultEmployeeId
      }
      const employeeNameOptionsObj = {
        all: defaultEmployeeName
      }
      employeesResponse.data.map((employee, i) => {
        if (!employeeIdOptionsObj[employee.id]) {
          employeeIdOptionsObj[employee.id] = {
            label: employee.id,
            value: employee.id
          }
        }

        if (!employeeNameOptionsObj[employee.name]) {
          employeeNameOptionsObj[employee.name] = {
            label: employee.name,
            value: employee.name
          }
        }

        employeesData.push({
          index: i + 1,
          id: employee.id,
          name: employee.name,
          role: employee.role,
          createdBy: employee.createdBy
        })
      })
      setEmployees(employeesData)
      setEmployeeIdOptions(Object.values(employeeIdOptionsObj))
      setEmployeeNameOptions(Object.values(employeeNameOptionsObj))
    } catch (err) {
      console.log(err)
      setEmployees([])
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (employeeRole === null) return

    fetchEmployees()
  }, [])

  const addEmployee = async (employeeData) => {
    const { employeeId, employeeName, employeeRole } = employeeData

    if (!employeeId) {
      showNotification('Employee id is required', 'error')
      return
    }

    if (!employeeName) {
      showNotification('Employee name is required', 'error')
      return
    }

    if (employeeRole === 0) {
      showNotification('Employee designation is required', 'error')
      return
    }

    const data = {
      id: employeeId,
      name: employeeName,
      role: employeeRole,
      createdBy: 'user1'
    }

    try {
      setIsLoading(true)
      const employeeResponse = await employeeService.addEmployee(data)
      console.log(employeeResponse)
      if (employeeResponse.success) {
        showNotification(`Employee added successfully`, 'success')
      } else {
        showNotification(employeeResponse.data.message, 'error')
      }
      setOpenAddEmployeeModal(false)
      fetchEmployees()
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const editEmployee = async (employeeData) => {
    const { employeeId, employeeName, employeeRole } = employeeData

    if (!employeeId) {
      showNotification('Employee id is required', 'error')
      return
    }

    if (!employeeName) {
      showNotification('Employee name is required', 'error')
      return
    }

    if (employeeRole === 0) {
      showNotification('Employee designation is required', 'error')
      return
    }

    const data = {
      name: employeeName,
      role: employeeRole,
      updatedBy: 'user1'
    }

    try {
      setIsLoading(true)
      const employeeResponse = await employeeService.editEmployee(
        employeeId,
        data
      )
      console.log(employeeResponse)
      if (employeeResponse.success) {
        showNotification(`Employee updated successfully`, 'success')
      } else {
        showNotification(employeeResponse.data.message, 'error')
      }
      setOpenAddEmployeeModal(false)
      fetchEmployees()
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const deleteEmployee = async (employeeId) => {
    if (!employeeId) {
      showNotification('Employee id is required', 'error')
      return
    }

    try {
      setIsLoading(true)
      const employeeResponse = await employeeService.deleteEmployee(employeeId)
      if (employeeResponse.data.success) {
        showNotification(`Employee deleted successfully`, 'success')
      } else {
        showNotification(employeeResponse.data.message, 'error')
      }
      fetchEmployees()
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const clearAll = async () => {
    setEmployeeName(defaultEmployeeName)
    setEmployeeId(defaultEmployeeId)
    setEmployeeRole(defaultDesignation)
    await fetchEmployees()
  }

  const employeeColumns = [
    {
      Header: 'S.No',
      accessor: 'index'
    },
    {
      Header: 'Emp ID',
      accessor: 'id'
    },
    {
      Header: 'Name',
      accessor: 'name'
    },
    {
      Header: 'Designation',
      accessor: 'role',
      Cell: ({ cell: { row } }) => {
        return getDisplayName(row.values.role, ROLES)
      }
    },
    {
      Header: 'Created by',
      accessor: 'createdBy'
    },
    {
      Header: 'Actions',
      Cell: ({ cell: { row } }) => {
        return (
          <div
            style={{ display: 'flex', gap: '8px', justifyContent: 'center' }}
          >
            <EditIcon
              onClickHandler={() => {
                setIsEditMode(true)
                setSelectedEmployee({ ...row.values })
                setOpenAddEmployeeModal(true)
              }}
            />
            <DeleteIcon
              onClickHandler={() => {
                setOpenDeleteModal(true)
                setSelectedEmployee({ ...row.values })
              }}
            />
          </div>
        )
      }
    }
  ]

  //1. Create search object
  const searchObj = {
    ...(employeeId &&
      (employeeId.value !== 'all' || employeeId.value === 0) && {
        id: employeeId.value
      }),
    ...(employeeName &&
      (employeeName.value !== 'all' || employeeName.value === 0) && {
        name: employeeName.value
      }),
    ...(employeeRole &&
      (employeeRole.value !== 'all' || employeeRole.value === 0) && {
        role: employeeRole.value
      })
  }

  //2. Extract the keys into an array
  const keysToChecked = Object.keys(searchObj)
  console.log(keysToChecked)

  //3. filter
  let filteredRows = employees
  if (keysToChecked.length > 0) {
    filteredRows = employees.filter((employee) => {
      return keysToChecked.every((key) => searchObj[key] === employee[key])
    })
  }

  console.log({ employees, employeeNameOptions, employeeIdOptions })

  return (
    <>
      <Stack
        sx={{
          position: 'sticky',
          top: 0,
          width: '100%',
          flexDirection: 'column',
          gap: '12px',
          borderBottom: '1px solid #eeeeee',
          backgroundColor: '#fff',
          padding: '12px 16px',
          zIndex: 100
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-between',
            alignItems: 'center',
            columnGap: '16px',
            padding: '0'
          }}
        >
          <Button
            // startIcon={<AddIcon />}
            variant="contained"
            sx={{
              textTransform: 'none',
              color: '#fff'
            }}
            onClick={() => {
              setIsEditMode(false)
              setSelectedEmployee({})
              setOpenAddEmployeeModal(true)
            }}
          >
            Add employee
          </Button>
          {keysToChecked.length > 0 && (
            <Button
              startIcon={<FilterAltOffIcon />}
              variant="outlined"
              sx={{
                textTransform: 'none',
                color: '#000'
              }}
              onClick={clearAll}
            >
              Clear
            </Button>
          )}
        </div>
        <Stack
          spacing={2}
          direction="row"
          sx={{ width: '100%', alignItems: 'center' }}
        >
          <StyledSelectField
            label=""
            placeHolderText="Select employee id"
            selectedValue={employeeId}
            selectOptions={employeeIdOptions}
            onChangeHandler={(value) => setEmployeeId(value)}
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No employee id found"
          />
          <StyledSelectField
            label=""
            placeHolderText="Select employee name"
            selectedValue={employeeName}
            selectOptions={employeeNameOptions}
            onChangeHandler={(value) => setEmployeeName(value)}
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No employee name found"
          />
          <StyledSelectField
            label=""
            placeHolderText="Select designation"
            selectedValue={employeeRole}
            selectOptions={ROLES}
            onChangeHandler={(value) => setEmployeeRole(value)}
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No designation found"
          />
        </Stack>
      </Stack>
      <Box sx={{ padding: '16px' }}>
        <StyledTableContainer>
          {isLoading && <StyledEmptyHeader>Loading...</StyledEmptyHeader>}
          {!isLoading && filteredRows.length === 0 && (
            <StyledEmptyHeader>There are no employees</StyledEmptyHeader>
          )}
          {!isLoading && filteredRows.length > 0 && (
            <StyledTable
              pagination={false}
              columns={employeeColumns}
              data={filteredRows}
              hiddenColumns={['createdBy']}
            />
          )}
        </StyledTableContainer>
      </Box>
      {openAddEmployeeModal && (
        <EmployeeUploadModal
          closeHandler={() => setOpenAddEmployeeModal(false)}
          isModalOpen={openAddEmployeeModal}
          addEmployee={addEmployee}
          editEmployee={editEmployee}
          isEditMode={isEditMode}
          selectedEmployee={selectedEmployee}
        />
      )}
      {openDeleteModal && (
        <ConfirmationModal
          isModalOpen={openDeleteModal}
          description={`Do you want to delete the following employee ${selectedEmployee.id}_${selectedEmployee.name}`}
          buttonTitle="Delete"
          clickHandler={() => deleteEmployee(selectedEmployee.id)}
          closeHandler={() => setOpenDeleteModal(false)}
        />
      )}
    </>
  )
}

export default EmployeeManagement
