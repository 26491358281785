import axios from 'axios'

const axiosObj = axios.create({
  baseURL: `${process.env.REACT_APP_ADMIN_BASE_URL}/v1`,
  headers: {
    'Content-Type': 'application/json'
  },
  responseType: 'json'
})

const machineService = {
  getMachines: async (data) => {
    try {
      const response = await axiosObj.get('/machines')

      return response.data
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  addMachine: async (data) => {
    try {
      const response = await axiosObj.post('/machines', data)
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  editMachine: async (id, data) => {
    try {
      const response = await axiosObj.patch(`/machines/${id}`, data)
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  deleteMachine: async (id, data) => {
    try {
      const response = await axiosObj.delete(`/machines/${id}`, data)
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  updateActiveList: async (machineIds, isActive) => {
    try {
      const response = await axiosObj.patch(`/machines/update/active-list`, {
        machineIds,
        isActive
      })
      return response.data
    } catch (error) {
      console.log(error)
      return error.response
    }
  }
}

export default machineService
