import React from 'react'
import ReactDOM from 'react-dom/client'
import { StyledEngineProvider } from '@mui/material'
import { BrowserRouter as Router } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'

import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID
const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE
const auth0Scope = process.env.REACT_APP_AUTH0_SCOPE

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      redirectUri={window.location.origin}
      audience={auth0Audience}
      scope={auth0Scope}
    >
      <Router>
        <StyledEngineProvider injectFirst>
          <App />
        </StyledEngineProvider>
      </Router>
    </Auth0Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
