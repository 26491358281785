import React from 'react'
import { styled } from '@mui/material/styles'

import RadialGauge from '../RadialGauge/RadialGauge'
import './AnalyticsCard.css'

const Container = styled('div')(() => ({
  display: 'flex',
  gap: '16px',
  padding: '12px',
  borderRadius: '8px',
  border: '1px solid gray'
}))

const InfoContainer = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: '8px',
  borderRadius: '8px',
  padding: '10px 0'
}))

const InfoContainer1 = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '8px'
}))

const InfoLabel = styled('div')(() => ({}))

const InfoValue = styled('div')(() => ({
  justifySelf: 'flex-end',
  fontWeight: 600
}))

const Dot = styled('div')(() => ({
  height: '8px',
  width: '8px',
  borderRadius: '50%',
  backgroundColor: 'gray'
}))

const CartTitle = styled('h4')(() => ({
  width: 'fit-content',
  borderBottom: '1px solid #000'
}))

const AnalyticsCard = (props) => {
  const {
    cardName,
    gaugeValue,
    totalLabel,
    totalValue,
    actualLabel,
    actualValue
  } = props
  return (
    <Container>
      <InfoContainer>
        <CartTitle>{cardName}</CartTitle>
        <InfoContainer1>
          <Dot color={'orange'} />
          <InfoLabel>{totalLabel}</InfoLabel>
          <InfoValue>{totalValue && totalValue.toLocaleString()}</InfoValue>
        </InfoContainer1>
        <InfoContainer1>
          <Dot color={'orange'} />
          <InfoLabel>{actualLabel}</InfoLabel>
          <InfoValue>{actualValue && actualValue.toLocaleString()}</InfoValue>
        </InfoContainer1>
      </InfoContainer>
      <RadialGauge value={gaugeValue} />
    </Container>
  )
}

export default AnalyticsCard
