import { toast } from 'react-toastify'

export const showNotification = (
  message,
  variant = 'error',
  position = 'TOP_RIGHT',
  autoClose = true
) => {
  toast[variant](message, {
    position: toast.POSITION[position],
    autoClose: autoClose ? 3000 : autoClose,
    hideProgressBar: true,
    closeOnClick: true
  })
}
