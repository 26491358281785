import React from 'react'
import AnalyticsCard from '../AnalyticsCard/AnalyticsCard'

import classes from './DashBoardAnalytics.module.css'

const DashBoardAnalytics = (props) => {
  const machines = props.isMachineWise ? 1 : 1
  const tillDate = props.data.totalDays || new Date().getDate() - 1

  let productionEfficiency = 0
  let percentageRejection = 0
  let percentageIdletime = 0

  let totalTarget = props.data.totalTarget
  let totalRejection = props.data.totalRejection
  let totalIdletime = props.data.totalIdleTime
  let totalAchievedQuantity = props.data.totalProduced
  let totalAvailableTime = machines * tillDate * 24 * 60

  productionEfficiency = parseInt((totalAchievedQuantity / totalTarget) * 100)

  percentageRejection = parseInt((totalRejection / totalAchievedQuantity) * 100)

  percentageIdletime = parseInt((totalIdletime / totalAvailableTime) * 100)

  return (
    <div className={classes.analyticsContainer}>
      <AnalyticsCard
        cardName="Production"
        gaugeValue={productionEfficiency}
        totalLabel="Produced"
        totalValue={totalAchievedQuantity}
        actualLabel="Target"
        actualValue={totalTarget}
      />
      <AnalyticsCard
        cardName="Rejection"
        gaugeValue={percentageRejection}
        totalLabel="Rejection"
        totalValue={totalRejection}
        actualLabel="Produced"
        actualValue={totalAchievedQuantity}
      />
      <AnalyticsCard
        cardName="Idletime"
        gaugeValue={percentageIdletime}
        totalLabel="Idletime"
        totalValue={totalIdletime}
        actualLabel="Available"
        actualValue={totalAvailableTime}
      />
    </div>
  )
}

export default DashBoardAnalytics
