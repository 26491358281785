import React, { useState, useEffect } from 'react'
import axios from 'axios'
import styled from '@emotion/styled/macro'
import EngineeringIcon from '@mui/icons-material/Engineering'
import { Typography } from '@mui/material'
import RippleEffect from '../../../components/RippleEffect/RippleEffect'

import { showNotification } from '../../../utils/toast.utils'
import { MACHINES } from '../../../utils/constant.utils'
import { getDisplayName } from '../../../utils/common.utils'

const Detail = ({ label, value }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        fontWeight: 500
      }}
    >
      <p style={{ color: 'gray' }}>{label}</p>
      <div
        style={{
          fontWeight: 700
        }}
      >
        {value}
      </div>
    </div>
  )
}

const CardContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  border-radius: 8px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid rgb(18 18 19 / 30%);
`

const Card = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
  background-color: #fff;
  color: #000;
  cursor: pointer;
`

const CardHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`

const Operator = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`

const MachineTitleContainer = styled.p`
  align-self: flex-start;
  display: flex;
  align-items: center;
`

const MachineTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
`

const CardBody = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const SingleMachineCard = ({ machine }) => {
  const [machineData, setMachineData] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchMachineStatus = async () => {
      try {
        setIsLoading(true)
        const response = await axios.get(
          `${process.env.REACT_APP_DATA_LOGGER_BASE_URL}/v1/live-status/${machine}`
        )
        if (response.data.success) {
          const data = response.data.data

          setMachineData({
            machine: data.machine,
            name: data.partName,
            type: data.partType,
            partCount: data.partCount,
            rejectionCount: data.rejectionCount,
            machineStatus: data.machineStatus,
            operator: data.operator,
            idleTime: data.idleTime,
            totalIdleTime: data.idleTimeFromDb
          })
        } else {
          showNotification(response.data.message, 'error')
        }
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }
    fetchMachineStatus()
    const id = setTimeout(() => {
      fetchMachineStatus()
    }, 30 * 1000)

    return () => clearTimeout(id)
  }, [machine])

  return (
    <CardContainer>
      {isLoading ? (
        <>Loading...</>
      ) : (
        <Card>
          <CardHeader>
            <MachineTitleContainer>
              <Typography sx={{ marginRight: '8px' }}>
                {' '}
                Live status of machine:{'     '}
              </Typography>
              <MachineTitle>{getDisplayName(machine, MACHINES)}</MachineTitle>
              <RippleEffect
                machineStatus={
                  machineData.machineStatus === true ||
                  machineData.machineStatus === 1
                    ? false
                    : true
                }
              />
            </MachineTitleContainer>
            <Operator>
              <EngineeringIcon fontSize="small" />
              <Typography variant="body1">{machineData.operator}</Typography>
            </Operator>
          </CardHeader>
          <CardBody>
            <Detail label="Part family:" value={machineData.name} />
            <Detail label="Part number:" value={machineData.type} />
            <Detail label="Part count:" value={machineData.partCount} />
            <Detail label="Rejection:" value={machineData.rejectionCount} />
            <Detail
              label="Idletime:"
              value={`${machineData.idleTime} Minutes`}
            />
          </CardBody>
        </Card>
      )}
    </CardContainer>
  )
}

export default SingleMachineCard
