import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import DomainVerificationIcon from '@mui/icons-material/DomainVerification'
//import EditOutlinedIcon from '@mui/icons-material/EditOutlined'

import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal'

//Utils
import { showNotification } from '../../../utils/toast.utils'

const breatheAnimation = keyframes`
 0%{transform: rotate(0deg);}
100%{ transform: rotate(350deg);}
`

const StyledDiv = styled.div`
  padding: 4px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 16px;
  &::before {
    content: '';
    position: absolute;
    width: 300px;
    height: 550%;
    background-image: ${(props) =>
      props.isActive
        ? 'conic-gradient(lightgreen, lightgreen)'
        : 'conic-gradient(transparent, transparent)'};
    animation: ${breatheAnimation} 4s linear infinite;
  }
`

const ActionButton = styled.button`
  display: inline-block;
  padding: 8px 16px;
  cursor: pointer;
  border: 1px solid gray;
  border-radius: 8px;
`

/**
 * Returns total shift time in minutes
 * @param {Number} start
 * @param {Number} end
 * @returns {Number}
 */
const getTotalTimeForShift = (start, end) => {
  let totalTime = 0
  if (start > end) {
    totalTime = 24 - start + end
  } else {
    totalTime = end - start
  }

  return totalTime * 60
}

const Detail = ({ label, value }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '8px',
        fontWeight: 500
      }}
    >
      <h4
        style={{
          fontWeight: 600
        }}
      >
        {label} :
      </h4>
      <div>{value}</div>
    </div>
  )
}

const ShiftCard = ({ shift, start, end, breakTime }) => {
  const totalTime = getTotalTimeForShift(start, end)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '8px',
        padding: '8px 16px',
        border: '1px solid gray',
        borderRadius: '8px'
      }}
    >
      <h3>Shift {shift}</h3>
      <Detail label="Start" value={`${start} hr`} />
      <Detail label="End" value={`${end} hr`} />
      <hr />
      <Detail label="Total time" value={`${totalTime} min`} />
      <Detail label="Break time" value={`${breakTime} min`} />
      <hr />
      <Detail label="Avilable time" value={`${totalTime - breakTime} min`} />
    </div>
  )
}

const ShiftConfigCard = ({ data, selectShiftConfig, deleteShiftConfig }) => {
  //hooks
  const navigate = useNavigate()

  //component state
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openSelectModal, setOpenSelectModal] = useState(false)

  const editShiftConfig = (id) => {
    navigate(`/workspace/config?tab=create-config&id=${id}`)
  }

  return (
    <StyledDiv isActive={data.isActive}>
      <div
        style={{
          position: 'relative',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: '24px',
          backgroundColor: 'white',
          borderRadius: '8px',
          zIndex: 10,
          cursor: 'pointer',
          border: '1px solid rgb(18 18 19 /30%)',
          '&:hover': {
            backgroundColor: 'lightblue'
          }
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Detail label="Name" value={data.name} />
          <Detail label="No of shift" value={data.numberOfShifts} />
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '16px',
            margin: '16px 0'
          }}
        >
          {React.Children.toArray(
            data.shiftData.map((data) => (
              <ShiftCard
                shift={data.shift}
                start={data.start}
                end={data.end}
                breakTime={data.breakTime}
              />
            ))
          )}
        </div>
        <div
          style={{
            display: 'flex'
          }}
        >
          <div
            style={{
              display: 'flex',
              columnGap: '16px',
              justifySelf: 'flex-end',
              marginLeft: 'auto'
            }}
          >
            <div
              onClick={() => {
                if (data.isActive) {
                  showNotification('This is already selected', 'info')
                  return
                }
                setOpenSelectModal(true)
              }}
            >
              <DomainVerificationIcon />
            </div>
            {/* <div onClick={() => editShiftConfig(data.id)}>
              <EditOutlinedIcon />
            </div> */}
            <div
              onClick={(e) => {
                e.stopPropagation()
                if (data.isActive) {
                  showNotification(
                    'You Can not delete running configuration',
                    'warn'
                  )
                  return
                }

                setOpenDeleteModal(true)
              }}
            >
              <DeleteOutlinedIcon />
            </div>
          </div>
        </div>
      </div>
      {openSelectModal && (
        <ConfirmationModal
          isModalOpen={openSelectModal}
          description={`You want to select the shift configuration ${data.name}`}
          buttonTitle="Select"
          clickHandler={() => selectShiftConfig(data.id)}
          closeHandler={() => setOpenSelectModal(false)}
        />
      )}
      {openDeleteModal && (
        <ConfirmationModal
          isModalOpen={openDeleteModal}
          description={`You want to delete the shift configuration: ${data.name}`}
          buttonTitle="Delete"
          clickHandler={() => deleteShiftConfig(data.id)}
          closeHandler={() => setOpenDeleteModal(false)}
        />
      )}
    </StyledDiv>
  )
}

export default ShiftConfigCard
