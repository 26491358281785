import React, { useRef } from 'react'
import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title
} from 'chart.js'

import { Bar } from 'react-chartjs-2'

import * as CHART_UTILS from './BarChart.constant'
import 'chart.js/auto' // ADD THIS

// console.log("Chart", {Chart, LineController})

Chart.register(LineController, LineElement, PointElement, LinearScale, Title)

const BarChart = ({
  chartLabels,
  dataGroup,
  yLabel,
  xLabel,
  minValue,
  maxValue,
  stepValue,
  height,
}) => {
  console.log({ chartLabels, dataGroup })

  const data = {
    labels: chartLabels,
    datasets: dataGroup
  }

  return (
    <Bar
      height={height || '40%'}
      width="100%"
      data={data}
      options={{
        responsive: true,
        plugins: {
          tooltip: CHART_UTILS.TOOLTIP,
          legend: {
            ...CHART_UTILS.LEGEND,
            display: false
          }
        },
        scales: {
          x: {
            ...CHART_UTILS.X_SCALE,
            title: { ...CHART_UTILS.X_SCALE.title, text: xLabel }
          },
          y: {
            ...CHART_UTILS.Y_SCALE,
            title: { ...CHART_UTILS.Y_SCALE.title, text: yLabel },
            min: minValue,
            max: maxValue,
            ticks: {
              stepSize: stepValue
            }
          }
        }
      }}
    />
  )
}

export default BarChart