import React, { useState, useEffect } from 'react'
import Stack from '@mui/material/Stack'

//components
import StyledTable from '../../components/ReactTable/StyledTable'
import StyledTableContainer from '../../components/StyledTableContainer/StyledTableContainer'
import StyledEmptyHeader from '../../components/StyledEmptyHeader/StyledEmptyHeader'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'

//utils
import { MACHINES } from '../../utils/constant.utils'

//services
import * as dashboardService from '../../service/dashboard.service'
import { getDisplayName } from '../../utils/common.utils'

// TODO: Enabled all machines update BE API to support machine grouping
// { label: 'All machines', value: 0 }
const machineOptions = [...MACHINES]

const SpillOver = () => {
  const defaultPartName = {
    value: 'all',
    label: 'All part numbers'
  }
  const defaultProductionOrderNum = {
    value: 'all',
    label: 'All PO numbers'
  }

  //local
  const [productionOrderRows, setProductionOrderRows] = useState([])
  const [machine, setMachine] = useState(machineOptions[0])
  const [isLoading, setIsLoading] = useState(false)
  const [productionOrderNumbers, setProductionOrderNumbers] = useState([])
  const [productionOrderNum, setProductionOrderNum] = useState(
    defaultProductionOrderNum
  )
  const [partNameOptions, setPartNameOptions] = useState([])
  const [partName, setPartName] = React.useState(defaultPartName)

  useEffect(() => {
    const fetchProductionOrderData = async () => {
      try {
        setIsLoading(true)
        const response = await dashboardService.getSpillOverData()
        console.log(response)
        const data = response.data.data.productionOrderData
        const productionOrderOptionsObj = {}
        const partNameOptionsObj = {}

        data.forEach((productionOrder, i) => {
          if (!productionOrderOptionsObj[productionOrder.workOrderNum]) {
            productionOrderOptionsObj[productionOrder.workOrderNum] = {
              label: productionOrder.workOrderNum,
              value: productionOrder.workOrderNum
            }
          }
          if (!partNameOptionsObj[productionOrder.partName]) {
            partNameOptionsObj[productionOrder.partName] = {
              label: productionOrder.partName,
              value: productionOrder.partName
            }
          }
        })

        setProductionOrderRows(data)
        setProductionOrderNumbers(Object.values(productionOrderOptionsObj))
        setPartNameOptions(Object.values(partNameOptionsObj))
      } catch (err) {
        console.log(err)
        setProductionOrderRows([])
      } finally {
        setIsLoading(false)
      }
    }
    fetchProductionOrderData()
  }, [])

  //1. Create search object
  const searchObj = {
    ...(machine &&
      (machine.value !== 'all' || machine.value === 0) && {
        machine: machine.value
      }),
    ...(productionOrderNum &&
      (productionOrderNum.value !== 'all' ||
        productionOrderNum.value === 0) && {
        workOrderNum: productionOrderNum.value
      }),
    ...(partName &&
      partName.value !== 'all' && {
        partName: partName.value
      })
  }

  //2. Extract the keys into an array
  const keysToChecked = Object.keys(searchObj)
  console.log('keysToChecked', keysToChecked, searchObj)

  //3. filter
  let filteredRows = productionOrderRows
  if (keysToChecked.length > 0) {
    filteredRows = productionOrderRows.filter((productionRecordRow) => {
      return keysToChecked.every(
        (key) => searchObj[key] === productionRecordRow[key]
      )
    })
  }

  const productionRecordColumns = [
    {
      Header: 'S.No',
      accessor: 'index'
    },
    {
      Header: 'Production order',
      accessor: 'workOrderNum'
    },
    {
      Header: 'Machine',
      accessor: 'machine',
      Cell: ({ cell: { row } }) => {
        return getDisplayName(row.values.machine, MACHINES)
      }
    },
    {
      Header: '	Part number',
      accessor: 'partName'
    },
    {
      Header: '	PO quantity',
      accessor: 'poQuantity'
    },
    {
      Header: 'Produced quantity',
      accessor: 'producedQuantity'
    },
    {
      Header: 'Leftover quantity',
      accessor: 'leftOverQuantity'
    }
  ]

  return (
    <>
      <Stack
        sx={{
          position: 'sticky',
          top: 0,
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '24px',
          padding: '16px',
          backgroundColor: '#fff',
          borderBottom: '1px solid #eeeeee'
        }}
      >
        <StyledSelectField
          label=""
          placeHolderText="Select machine"
          selectedValue={machine}
          selectOptions={machineOptions}
          onChangeHandler={(value) => setMachine(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No machine found"
        />
        <StyledSelectField
          label=""
          placeHolderText="Select PO number"
          selectedValue={productionOrderNum}
          selectOptions={[defaultProductionOrderNum, ...productionOrderNumbers]}
          onChangeHandler={(value) => setProductionOrderNum(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No PO found"
        />
        <StyledSelectField
          label=""
          placeHolderText="Select part number"
          selectedValue={partName}
          selectOptions={[defaultPartName, ...partNameOptions]}
          onChangeHandler={(value) => setPartName(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No part number found"
        />
      </Stack>
      <Stack
        sx={{
          gap: '16px',
          padding: '16px'
        }}
      >
        {isLoading ? (
          <>Loading...</>
        ) : (
          <StyledTableContainer>
            {!isLoading && filteredRows.length === 0 && (
              <StyledEmptyHeader>
                There are no production order data
              </StyledEmptyHeader>
            )}
            {!isLoading && filteredRows.length > 0 && (
              <StyledTable
                pagination={false}
                columns={productionRecordColumns}
                data={filteredRows}
                hiddenColumns={[]}
              />
            )}
          </StyledTableContainer>
        )}
      </Stack>
    </>
  )
}

export default SpillOver
