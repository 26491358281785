import React, { useState } from 'react'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

import StyledModal from '../../components/StyledModal/StyledModal'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'
import StyledTextFieldWithLabel from '../../components/StyledTextFieldWithLabel/StyledTextFieldWithLabel'

const ROLES = [
  {
    value: 'operator',
    label: 'Operator'
  },
  {
    value: 'lineInspector',
    label: 'line Inspector'
  },
  {
    value: 'supervisor',
    label: 'Supervisor'
  }
]

const EmployeeUploadModal = (props) => {
  let defaultEmployeeId = ''
  let defaultEmployeeName = ''
  let defaultEmployeeRole = {
    value: 0,
    label: 'Select designation'
  }

  if (props.isEditMode) {
    defaultEmployeeId = props.selectedEmployee.id
    defaultEmployeeName = props.selectedEmployee.name
    defaultEmployeeRole = {
      value: props.selectedEmployee.role,
      label: props.selectedEmployee.role.toUpperCase()
    }
  }

  const [employeeId, setEmployeeId] = useState(defaultEmployeeId)
  const [employeeName, setEmployeeName] = useState(defaultEmployeeName)
  const [employeeRole, setEmployeeRole] = useState(defaultEmployeeRole)

  return (
    <StyledModal
      modalWidth={500}
      modalHeight={500}
      loaderState={props.isLoading}
      isModalOpen={props.isModalOpen}
      modalTitle="Add employee"
      modalCloseHandler={() => props.closeHandler()}
    >
      <Stack
        spacing={2}
        direction="column"
        sx={{ marginBottom: '16px', width: '100%' }}
      >
        <StyledTextFieldWithLabel
          label="Employee id"
          placeholderText="Enter employee id.."
          textValue={employeeId}
          onChangeHandler={(e) => setEmployeeId(e.target.value)}
          isDisabled={props.isEditMode ? true : false}
          type="text"
        />
        <StyledTextFieldWithLabel
          label="Employee name"
          placeholderText="Enter employee name.."
          textValue={employeeName}
          onChangeHandler={(e) => setEmployeeName(e.target.value)}
          isDisabled={false}
          type="text"
        />

        <StyledSelectField
          label="Designation"
          placeHolderText="Select designation"
          selectedValue={employeeRole}
          selectOptions={ROLES}
          onChangeHandler={(value) => setEmployeeRole(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No designation found"
        />
      </Stack>
      <Stack
        spacing={2}
        direction="row"
        sx={{
          marginTop: '16px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button variant="outlined" onClick={props.closeHandler}>
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            color: '#fff'
          }}
          onClick={() => {
            !props.isEditMode
              ? props.addEmployee({
                  employeeId,
                  employeeName,
                  employeeRole: employeeRole.value
                })
              : props.editEmployee({
                  employeeId,
                  employeeName,
                  employeeRole: employeeRole.value
                })
          }}
        >
          {props.isEditMode ? 'Update' : 'Add employee'}
        </Button>
      </Stack>
    </StyledModal>
  )
}

export default EmployeeUploadModal
