import React, { useEffect, useRef, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import styled from 'styled-components'

const ProfileInfoContainer = styled.div`
  position: relative;
`

export const ProfileInfoBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #b2d8fb;
  padding: 8px;
  border-radius: 50px;
  cursor: pointer;
`

const ProfileDropdown = styled.div`
  position: absolute;
  top: 115%;
  right: 0px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #fff;
  box-shadow:
    rgb(15 15 15 / 10%) 0px 0px 0px 1px,
    rgb(15 15 15 / 10%) 0px 2px 4px;
  z-index: 10;
`

const ProfileInfo = styled.div`
  display: flex;
  column-gap: 8px;
  padding: 16px;
  align-items: center;
`

const ProfileNameEmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  font-size: 14px;
  max-width: 200px;
`
const ProfileItem = styled.p`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 !important;
  margin: 0 !important;
  text-transform: ${(props) => (props.capitalize ? 'capitalize' : 'none')};
`
export const DropdownItems = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 0;
`

export const DropdownItem = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
  padding: 8px 24px;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background-color: #f3f3f3;
  }
  svg {
    color: #696969;
  }
`
export const DropdownHref = styled.a`
  display: flex;
  column-gap: 8px;
  align-items: center;
  text-decoration: none;
  padding: 8px 24px;
  color: #000;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background-color: #f3f3f3;
  }
  svg {
    color: #696969;
  }
`

const UserInfo = () => {
  //hooks
  const profileDropdownRef = useRef(null)
  const { logout, user: userInfo } = useAuth0()

  //component state
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false)

  useEffect(() => {
    // Alert if clicked on outside of element
    function handleClickOutside(event) {
      if (
        profileDropdownRef.current &&
        !profileDropdownRef.current.contains(event.target)
      ) {
        setIsProfileDropdownOpen(false)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [profileDropdownRef])

  const logOutHandler = () => {
    logout({ returnTo: window.location.origin })
  }

  const getInitials = (firstName, lastName) => {
    return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()
  }

  let displayName = ''
  if (userInfo.name && userInfo.nickname) {
    displayName = getInitials(userInfo.name, userInfo.nickname)
  }

  return (
    <ProfileInfoContainer ref={profileDropdownRef}>
      <ProfileInfoBox onClick={() => setIsProfileDropdownOpen(true)}>
        {displayName}
      </ProfileInfoBox>
      {isProfileDropdownOpen && (
        <ProfileDropdown>
          <ProfileInfo>
            <ProfileInfoBox>{displayName}</ProfileInfoBox>
            <ProfileNameEmailWrapper>
              <ProfileItem capitalize={true}>{userInfo.nickname}</ProfileItem>
              <ProfileItem>{userInfo.email}</ProfileItem>
            </ProfileNameEmailWrapper>
          </ProfileInfo>
          <hr style={{ padding: 0, margin: 0, backgroundColor: '#000' }} />
          <DropdownItems>
            <DropdownItem onClick={logOutHandler}>
              <i class="fa fa-power-off" aria-hidden="true"></i>
              Logout
            </DropdownItem>
          </DropdownItems>
        </ProfileDropdown>
      )}
    </ProfileInfoContainer>
  )
}

export default UserInfo
