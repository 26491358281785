import * as React from 'react'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { Route, Routes } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import CircularProgress from '@mui/material/CircularProgress'

//context
import ToastContext from './context/ToastContext'

//Layout
import EntryLayout from './layouts/EntryLayout'
import DashboardLayout from './layouts/DashboardLayout'
import AdminLayout from './layouts/AdminLayout'

//Dashboard
import DashboardOutlet from './pages/Dashboard/DashboardOutlet'

//pages
import LiveDataOutlet from './pages/LiveData/LiveDataOutlet'
import MachineWiseData from './pages/MachineWiseData/MachineWiseData'
import ShiftConfig from './pages/ShiftConfig/ShiftConfig'
import ManageData from './pages/ManageData'
import ToolLife from './pages/ToolLife'
import Updates from './pages/Updates'
import HistoricalDashboard from './pages/HistoricalData/DashboardOutlet'

import NotFound from './components/NotFound/NotFound'

//styles and assets
import inputGlobalStyles from './theme/GlobalStyles'
import { lightTheme } from './theme/theme'
import './App.css'

const App = () => {
  const { isLoading, isAuthenticated, error, loginWithRedirect } = useAuth0()

  if (isLoading) {
    return (
      <div
        style={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '8px'
        }}
      >
        <CircularProgress />
        <p>Technymon India</p>
      </div>
    )
  }

  if (error) {
    return <div>Oops... {error.message}</div>
  }

  if (!isLoading && !error && !isAuthenticated) {
    loginWithRedirect()
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <ToastContext>
        <CssBaseline />
        {/* Note: It is a good practice to hoist the <GlobalStyles /> to a static constant, to avoid rerendering. 
        This will ensure that the <style> tag generated would not recalculate on each render. */}
        {inputGlobalStyles}
        <Routes>
          <Route index element={<EntryLayout />} />
          <Route path="dashboard" element={<DashboardLayout />}>
            <Route index element={<DashboardOutlet />} />
            <Route path="live-data" element={<LiveDataOutlet />} />
            <Route path="machine-wise" element={<MachineWiseData />} />
            <Route path="tool-life" element={<ToolLife />} />
            <Route path="historical" element={<HistoricalDashboard />} />
          </Route>
          <Route path="admin" element={<AdminLayout />}>
            <Route index element={<ManageData />} />
            <Route path="shift-config" element={<ShiftConfig />} />
            <Route path="hmi-update" element={<Updates />} />
            <Route path="tool-life" element={<ToolLife />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ToastContext>
    </ThemeProvider>
  )
}

export default App
