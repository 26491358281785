export const MACHINES = [{ label: 'OMAS 03-25', value: 'OMAS_03_25' }]

export const ROLES = [
  {
    value: 'operator',
    label: 'Operator'
  },
  {
    value: 'supervisor',
    label: 'Supervisor'
  },
  {
    value: 'lineInspector',
    label: 'Line Inspector'
  }
]
