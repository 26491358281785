import React, { useState } from 'react'
import { styled } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

//service
import hmiUpdateService from '../../service/hmiUpdate.service'

//utils
import { showNotification } from '../../utils/toast.utils'
import { MACHINES } from '../../utils/constant.utils'
import ShiftResetConfirmation from './ShiftResetConfirmation'

const CardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '16px',
}))

const Card = styled('div')<{ isMaster?: boolean }>(({ isMaster }) => ({
  position: 'relative',
  width: '300px',
  height: '100px',
  display: 'flex',
  justifyContent: 'Center',
  alignItems: 'center',
  padding: '8px 16px',
  textAlign: 'center',
  color: '#000',
  borderRadius: '8px',
  cursor: 'pointer',
  border: `1px solid ${isMaster ? 'red' : 'rgb(18 18 19 /30%)'}`,
  backgroundColor: isMaster ? '#ccccd7' : '#fff',
  '&:hover': {
    backgroundColor: '#ccccd7',
    color: 'black'
  }
}))

const Title = styled('div')(({}) => ({
  textAlign: 'center',
  overflow: 'hidden', //Don't remove
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
}))

const LoadingContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '16px',
  rowGap: '16px',
  fontWeight: 600
}))

const SHIFT_RESET = 'shift reset'
const MASTER_RESET = 'master reset'

const ShiftReset = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [currentMachine, setCurrentMachine] = useState<{
    label: string
    value: string
  } | null>(null)
  const [openResetConfirmModal, setOpenResetConfirmModal] = useState(false)
  const [openMasterResetConfirmModal, setOpenMasterResetConfirmModal] =
    useState(false)

  const resetShift = async (machine: string, clearShiftData: boolean) => {
    try {
      setIsLoading(true)
      const updateResponse = await hmiUpdateService.resetShift(
        machine,
        clearShiftData
      )
      if (updateResponse.success) {
        showNotification(`${machine} shift reset successful`, 'success')
      } else {
        showNotification(updateResponse.data.message, 'error')
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
      setOpenResetConfirmModal(false)
    }
  }

  const masterShiftReset = async (clearShiftData: boolean) => {
    try {
      setIsLoading(true)
      const updateResponse =
        await hmiUpdateService.masterShiftReset(clearShiftData)
      if (updateResponse.success) {
        showNotification(`Master shift reset successful`, 'success')
      } else {
        showNotification(updateResponse.data.message, 'error')
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
      setOpenMasterResetConfirmModal(false)
    }
  }

  if (isLoading) {
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    )
  }

  return (
    <>
      <CardContainer>
        <Card
          isMaster
          onClick={() => {
            setOpenMasterResetConfirmModal(true)
          }}
        >
          <Title>Master reset</Title>
        </Card>
        {MACHINES.map((machine) => (
          <Card
            onClick={() => {
              setCurrentMachine(machine)
              setOpenResetConfirmModal(true)
            }}
          >
            <Title>{machine.label}</Title>
          </Card>
        ))}
      </CardContainer>
      {currentMachine && (
        <ShiftResetConfirmation
          confirmText={SHIFT_RESET}
          title="Shift reset"
          closeHandler={() => setOpenResetConfirmModal(false)}
          isModalOpen={openResetConfirmModal}
          confirmationHandler={(isClearChecked: boolean) =>
            resetShift(currentMachine.value, isClearChecked)
          }
        />
      )}
      <ShiftResetConfirmation
        confirmText={MASTER_RESET}
        title="Master reset"
        closeHandler={() => setOpenMasterResetConfirmModal(false)}
        isModalOpen={openMasterResetConfirmModal}
        confirmationHandler={(isClearChecked: boolean) =>
          masterShiftReset(isClearChecked)
        }
      />
    </>
  )
}

export default ShiftReset
