import React, { useState } from 'react'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

import StyledModal from '../../components/StyledModal/StyledModal'
import StyledTextFieldWithLabel from '../../components/StyledTextFieldWithLabel/StyledTextFieldWithLabel'

const AddToolTypeModal = (props) => {
  let defaultToolType = ''

  if (props.isEditMode) {
    defaultToolType = props.selectedTool.type
  }

  const [toolType, setToolType] = useState(defaultToolType)

  return (
    <StyledModal
      modalWidth={500}
      loaderState={props.isLoading}
      isModalOpen={props.isModalOpen}
      modalTitle={props.isEditMode ? 'Edit tool type ' : 'Add tool type'}
      modalCloseHandler={() => props.closeHandler()}
    >
      <Stack
        spacing={2}
        direction="column"
        sx={{ marginBottom: '16px', width: '100%' }}
      >
        <StyledTextFieldWithLabel
          label=""
          placeholderText="Enter tool type"
          textValue={toolType}
          onChangeHandler={(e) => setToolType(e.target.value)}
          isDisabled={false}
          type="text"
          isMargin={true}
        />
      </Stack>
      <Stack
        spacing={2}
        direction="row"
        sx={{
          marginTop: '16px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button variant="outlined" onClick={props.closeHandler}>
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            color: '#fff'
          }}
          onClick={() =>
            props.isEditMode
              ? props.editTool(props.selectedTool.id, toolType)
              : props.addTool(toolType)
          }
        >
          {props.isEditMode ? 'Update' : 'Add'}
        </Button>
      </Stack>
    </StyledModal>
  )
}

export default AddToolTypeModal
